import $ from 'jquery';
import _ from 'underscore';
import * as urlUtil from '@bingads-webui/url-util';
import { http } from '@bingads-webui/http-util';

const checkFrequencyInMinutes = 5;

function fullFillUrl(relativeUrl) {
  const domain = urlUtil.getDomain(window.location.href);
  return `${window.location.protocol}//${domain.replace(/ui\./i, '')}${relativeUrl}`;
}

export class KeepAlive {
  /**
   * initialize Keep Alive Class
   * @param {object} [deps] - provide dep injection for keep alive
   * @param {string} [url] - url to keep alive, if pass absolute url, rewrite url will be useless
   * @param {function} [reWriteUrl] - function to rewrite url when pass relative url
   * @param {number} [timeoutInMinutes] - user is idle but we keep session alive duration
   * @param {function} [unAuthCallBack] - call back when get 401 from keep alive
   * @returns {void}
   */
  constructor({
    deps = {
      httpUtil: http,
    },
    url = '/root/keepalive?__adcenterAJAX=true',
    requestOption = {
      converters: {
        'text json': function (data) {
          if (_.isEmpty(data)) {
            return null;
          }
          return JSON.parse(data);
        },
      },
      xhrFields: {
        withCredentials: true,
      },
      contentType: 'application/x-www-form-urlencoded',
    },
    reWriteUrl = fullFillUrl,
    timeoutInMinutes = 240,
    unAuthCallBack = _.identity,
  }) {
    this.url = url;
    if (urlUtil.isRelativeUrl(url)) {
      this.url = reWriteUrl(url);
    }
    this.deps = deps;
    this.keepAliveTask = null;
    this.checkFrequency = checkFrequencyInMinutes * 1000 * 60;
    this.keepAliveCounter = 0;
    this.keepAliveMaxCount = timeoutInMinutes / checkFrequencyInMinutes;
    this.unAuthCallBack = unAuthCallBack;
    this.requestOption = requestOption;
  }

  issueKeepAliveRequest() {
    return this.deps.httpUtil.get(this.url, this.requestOption).then((data = {}) => {
      if (_.result(data, 'Error') === '401') {
        this.unAuthCallBack(data);
        return false;
      }
      return true;
    }).catch((reason) => {
      if (_.result(reason, 'status') === 200) {
        return true;
      }
      return false;
    }).then((result) => {
      if (result) {
        this.keepAliveCounter = this.keepAliveCounter + 1;
      }
      return result;
    });
  }

  issueKeepAliveRequestWithExceptionHandling() {
    try {
      this.issueKeepAliveRequest();
    // eslint-disable-next-line no-empty
    } catch (e) {}
  }

  start() {
    this.stop();
    this.issueKeepAliveRequestWithExceptionHandling();
    this.keepAliveTask = setInterval(() => {
      if (this.keepAliveCounter <= this.keepAliveMaxCount) {
        this.issueKeepAliveRequestWithExceptionHandling();
      }
    }, this.checkFrequency);

    $(window).on('unload', () => this.stop());
  }

  stop() {
    if (this.keepAliveTask !== null) {
      clearInterval(this.keepAliveTask);
      this.keepAliveTask = null;
    }
  }

  refresh() {
    this.keepAliveCounter = 0;
  }
}

export const keepAliveInstance = new KeepAlive({});
