import _ from 'underscore';
import { get } from '@bingads-webui-universal/primitive-utilities';

// TODOs [imang]:
// 1. functions to build odata Uri should be generalized
// in odata-core and be removed from this file.
// 2. error handling: errors should be propagated all the way
// to their place inside the object hierarchy.
// for now, they are aggregated in the errors field in the top level of
// each returned instance to the caller.
// 3. referencing should only be implicitly done by the caller.
// for now, all sub-entities inside Campaigns/AdGroups
// should explicitly specify either the Campaign/AdGroupId
// or Campaign/AdGroupName fields in order to define the relationship.
// this should be changed in a way that adapter finds
// the related ids based on the hierarchy definition.

function getCast(options) {
  return (options && !_.isEmpty(options.cast)) ? (`/${options.cast}`) : '';
}

function getParent(options) {
  return (options && !_.isEmpty(options.parent)) ? (`${options.parent}/`) : '';
}

export function getUri(options) {
  return `/Customers(:advertisercustomer_id)/Accounts(:account_id)/${getParent(options)}Default.BulkUpsert${getCast(options)}`;
}

export function extractEntityErrors(errors, index) {
  return _.filter(errors, error => parseInt(error['Client Id'][0], 10) === index);
}

export function buildDestroyObject(parent, id, parentId) {
  const obj = {
    Id: id,
    Status: 'Deleted',
  };

  const parentFkObject = {
    Id: _.isUndefined(parentId) ? '' : parentId,
  };

  if (parent) {
    obj[parent] = parentFkObject;
  }

  return obj;
}

function buildCreateObject(attrs) {
  return _.omit(attrs, 'Id');
}

export function hasReadOnlyFields(attrs, properties) {
  return _.some(attrs, (v, k) => properties[k] && properties[k].isReadOnly);
}

export function getAttrsToSerialize(id, attrs, schema) {
  if (hasReadOnlyFields(attrs, schema.properties)) {
    const parentId = get(
      get(attrs, schema.parent, {}),
      'Id',
      undefined
    );

    return [
      buildDestroyObject(schema.parent, id, parentId),
      buildCreateObject(attrs),
    ];
  }

  return _.defaults(attrs, {
    Id: id,
  });
}
