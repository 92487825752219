import _ from 'underscore';
import { get } from '@bingads-webui-universal/primitive-utilities';

function needCast(definition, options) {
  // _.has means has own, it will not check property in __proto__
  const jsdataResource = _.has(options, 'entitySet') ?
    get(options, 'entitySet.type.elementType.jsdataResource') :
    get(options, 'entitySet.jsdataResource');

  return !_.isEmpty(jsdataResource) && definition !== jsdataResource;
}

export function resIds({ resIds: ids, definition, options }) {
  return needCast(definition, options) ? ids.$cast(`Model.${definition.name}`) : ids;
}

export function data({ data: d, definition, options }) {
  const extra = needCast(definition, options) ? { '@odata.type': `Model.${definition.name}` } : {};

  return _.extend(extra, d);
}
