import _ from 'underscore';

/**
 * Create an instance of Performance Timing Logger
 */
export class PerformanceTimingLogger {
  constructor(options = {}) {
    this.instrumentation = options.instrumentation;
    this.updateInterval = options.updateInterval || 2500;
  }

  /**
   * init performance timing logging
   * @returns {undefined} - no returns
   */
  init() {
    if (!_.isFunction(window.performance.getEntriesByType)) {
      return;
    }

    this.isPageLoaded = false;
    this.pageNavLogged = false;

    if (document.readyState === 'complete') {
      this.logOnLoad();
    } else {
      window.addEventListener('load', () => this.logOnLoad(), false);
    }

    this.timer = setInterval(() => this.logOnTimer(), this.updateInterval);

    this.instrumentation.beforeStop = () => {
      this.logOnUnload();
    };
  }

  /**
   * @private
   * @returns {undefined} - no returns
   */
  logOnLoad() {
    this.writeResourceTimingLogs();
    this.tryRecordInactiveTime();
    this.tryLogPageNavigation();
    this.isPageLoaded = true;
  }

  /**
   * @private
   * @returns {undefined} - no returns
   */
  logOnTimer() {
    this.writeResourceTimingLogs();
    this.tryLogPageNavigation();
  }

  /**
   * @private
   * @returns {undefined} - no returns
   */
  logOnUnload() {
    this.writeResourceTimingLogs();
  }

  /**
   * writes resource timing logs
   * @private
   * @returns {undefined} - no returns
   */
  writeResourceTimingLogs() {
    const logs = window.performance.getEntriesByType('resource');

    this.clearLogBuffer();

    if (!logs.length) {
      return;
    }

    _.each(logs, (log) => {
      _.extend(log, { pageLoaded: this.isPageLoaded });
      this.instrumentation.writeRawLog(log);
    });
  }

  /**
   * If supported, record the amount of time the page was in the background.
   * It gets the foreground time from window.performance.activeTime
   * @private
   * @returns {undefined} - no returns
   */
  tryRecordInactiveTime() {
    if (_.isFunction(window.performance.activeTime)) {
      this.inactiveTime = window.performance.now() - window.performance.activeTime();
    }
  }

  /**
   * try log page navigation logs
   * @private
   * @returns {bool} - if page navigation has been logged
   */
  tryLogPageNavigation() {
    if (!this.pageNavLogged &&
      window.performance.timing &&
      window.performance.timing.loadEventEnd > 0) {
      this.pageNavLogged = this.writePageNavigationLogs();
    }

    return this.pageNavLogged;
  }

  /**
   * writes page navigation logs
   * @private
   * @returns {bool} - if page navigation has been logged
   */
  writePageNavigationLogs() {
    const extensions = {
      isPageNavigationLog: true,
    };

    if (window.chrome && _.isFunction(window.chrome.loadTimes)) {
      const { firstPaintTime } = (window.chrome.loadTimes() || {});

      if (_.isNumber(firstPaintTime) && firstPaintTime > 0) {
        extensions.firstPaintTime = firstPaintTime * 1000;
      } else {
        return false;
      }
    }

    const navTimingV2Logs = window.performance.getEntriesByType('navigation');

    if (_.isArray(navTimingV2Logs) && _.size(navTimingV2Logs) > 0) {
      const navTimingV2Log = _.first(navTimingV2Logs);
      const colsToReplace = _.pick(
        navTimingV2Log,
        'unloadEventStart', 'unloadEventEnd', 'redirectCount', 'redirectStart'
        , 'redirectEnd'
      );
      _.extend(extensions, colsToReplace);
    } else {
      extensions.redirectCount = window.performance.navigation.redirectCount;
    }

    if (this.inactiveTime) {
      extensions.inactiveTime = this.inactiveTime;
    }

    this.instrumentation.writeRawLog(_.extend({}, window.performance.timing, extensions));
    return true;
  }

  /**
   * @private
   * @returns {undefined} - no returns
   */
  clearLogBuffer() {
    if (_.isFunction(window.performance.clearResourceTimings)) {
      window.performance.clearResourceTimings();
    } else if (_.isFunction(window.performance.webkitClearResourceTimings)) {
      window.performance.webkitClearResourceTimings();
    }
  }
}
