/**
 * the types plugin module
 */
import _ from 'underscore';

import {
  defineConstProperty,
  defineProducedProperty,
  defineProducedPropertyOnClass,
} from '@bingads-webui/reflection';

import { Registry } from '@bingads-webui/registry';

import {
  ONEOF_TYPE_PREFIX,
  createResolveType,
  createProperty,
  createParameter,
  createType,
  createPrimitiveType,
  createCollectionType,
  compileProperties,
  compileParameters
} from '@bingads-webui/edm-shared';

/**
 * @param {EDM} edm - The EDM object to apply this plugin to
 * @return {void}
 */
export default (edm) => {
  if (edm.types) {
    return;
  }

  // eslint-disable-next-line no-undef
  const oneOfTypes = new Map();

  /**
   * @name edm
   * @type {EDM}
   * @property {Registry} types - A registry of all types
   * @property {Class} types.Type - The base type of all meta Types
   * @property {Class} types.PrimitiveType - The meta type of primitive types
   * @property {Class} types.ObjectType - The meta type of the key/value types
   * @property {Class} types.ComplexType - The meta type of the complex types
   * @property {Class} types.EntityType - The meta type of the entity types
   * @property {Class} types.CollectionType - The meta type of the collection types
   * @property {Class} types.CallableType - The meta type of the callable types
   */
  defineConstProperty(edm, 'types', (() => {
    const types = new Registry();
    const Type = createType(types);
    const PrimitiveType = createPrimitiveType(Type);

    /**
     * @class OneOfType
     * @extends Type
     * @property {Class} types - Data could be one of these types
     * @see {@link http://json-schema.org/latest/json-schema-validation.html#rfc.section.6.7.3}
     */
    class OneOfType extends Type {
      /**
       * Create a PrimitiveType
       * @param {Object} options - The constructor options
       * @param {String} options.name - The name of the type
       * @param {Type[]} options.types - The JavaScript type of the primitive type
       * @return {void}
       */
      constructor({
        typeNames,
      }) {
        super({
          name: OneOfType.oneOfTypeName(typeNames),
        });

        defineConstProperty(this, 'typeNames', typeNames);
        // eslint-disable-next-line no-use-before-define
        defineConstProperty(this, 'types', typeNames.map(typeName => resolveType(typeName, this.namespace)));
      }

      /**
       * Get the OneOfType's name from it's elements type name
       * @param {String[]} typeNames - The name of the elementTypes
       * @return {String} The name of the OneOfType
       */
      static oneOfTypeName(typeNames) {
        // We don't support nested one for now
        const sortedNames = typeNames.map(name =>
          types.resolveQualifiedName(name, this.namespace)).sort();

        return `${ONEOF_TYPE_PREFIX}(${sortedNames.join(',')})`;
      }
    }

    const resolveType = createResolveType(oneOfTypes, types, OneOfType);
    const Property = createProperty(resolveType);
    const Parameter = createParameter(resolveType);

    /**
     * @class ObjectType
     * @extends Type
     * @property {Object.<String, Property>} properties - The properties of the object type
     * @property {String} baseTypeName - The name of the base type
     * @property {Type} baseType - The base type
     */
    class ObjectType extends Type {
      /**
       * Create an ObjectType
       * @param {Object} options - The constructor options
       * @param {String} options.name - The name of the type
       * @param {Object.<String, PropertyInfo>} options.properties - The property definition
       * @param {string[]} [options.navigationPropertyNames] - Navigation properties names
       * @param {String} options.baseTypeName - The name of the base type
       * @return {void}
       */
      constructor({
        name,
        properties,
        navigationPropertyNames = [],
        baseTypeName,
      }) {
        super({ name });

        defineConstProperty(this, 'properties', compileProperties.call(this, properties, Property));
        defineConstProperty(this, 'navigationPropertyNames', navigationPropertyNames.slice());
        defineProducedProperty(this, 'navigationProperties', () => _.pick(this.properties, (property, propertyName) => _.contains(this.navigationPropertyNames, propertyName)));
        if (baseTypeName) {
          defineConstProperty(this, 'baseTypeName', baseTypeName);
          defineProducedProperty(this, 'baseType', () => resolveType(this.baseTypeName, this.namespace));
        }
      }

      addProperties(properties) {
        _.extend(this.properties, compileProperties.call(this, properties, Property));
        // only for backward compability, should use addNavigationProperties for this case
        this.navigationPropertyNames.push(..._.keys(properties));
      }

      addNavigationProperties(properties) {
        this.addProperties(properties);
      }
    }

    /**
     * @class ComplexType
     * @extends ObjectType
     */
    class ComplexType extends ObjectType {

    }

    /**
     * @class EntityType
     * @extends ObjectType
     * @property {String} key - The name of the key property
     * @property {Property} keyProperty - The key property of the entity type
     * @return {void}
     */
    class EntityType extends ObjectType {
      /**
       * Create an EntityType
       * @param {Object} options - The constructor options
       * @param {String} options.name - The name of the type
       * @param {Object.<String, PropertyInfo>} options.properties - The property definition
       * @param {String} options.baseTypeName - The name of the base type
       * @param {String} [options.key] - The name of the key property
       * @return {void}
       */
      constructor({
        name,
        properties,
        navigationPropertyNames,
        baseTypeName,
        key,
      }) {
        super({
          name,
          properties,
          navigationPropertyNames,
          baseTypeName,
        });

        if (key) {
          defineConstProperty(this, 'key', key);
          defineConstProperty(this, 'keyProperty', this.properties[this.key]);
        } else if (baseTypeName) {
          // The key property is inherited if there's a base type
          defineProducedProperty(this, 'key', () => this.baseType.key);
          defineProducedProperty(this, 'keyProperty', () => this.baseType.keyProperty);
        } else {
          throw new Error('The "key" property is required for an EntityType');
        }
      }
    }

    const CollectionType = createCollectionType(ObjectType, resolveType);

    /**
     * @memberof Type#
     * @this Type
     * @returns {CollectionType} The CollectionType of the given Type
     */
    function collectionTypeFactory() {
      return new CollectionType({ elementTypeName: this.name });
    }

    /**
     * @name type
     * @type Type
     * @property {CollectionType} collectionType - The collection type of the given type
     */
    defineProducedPropertyOnClass(Type, 'collectionType', collectionTypeFactory);

    /**
     * @class CallableType
     * @property {Parameter[]} parameters - The parameters required to call the callable
     * @property {String} returnTypeName - The name of the return type
     * @property {Type} returnType - The return type of the callable
     */
    class CallableType extends Type {
      /**
       * Create a CallableType
       * @param {Object} options - The constructor options
       * @param {String} options.name - The name of the type
       * @param {Object.<String, ParameterInfo>} parameters - The parameter definitions
       * @param {String} returnTypeName - The name of the return type
       * @return {void}
       */
      constructor({
        name,
        callableName,
        parameters,
        returnTypeName,
      }) {
        super({ name });

        defineConstProperty(this, 'callableName', callableName);
        defineConstProperty(this, 'parameters', compileParameters.call(this, parameters, Parameter));
        defineConstProperty(this, 'returnTypeName', returnTypeName);
        defineProducedProperty(this, 'returnType', () => resolveType(returnTypeName, this.namespace));
      }
    }

    /**
     * @class ActionType
     * @property {Parameter[]} parameters - The parameters required to call the callable
     * @property {String} returnTypeName - The name of the return type
     * @property {Type} returnType - The return type of the callable
     */
    class ActionType extends CallableType {
      /**
       * Create a CallableType
       * @param {Object} options - The constructor options
       * @param {String} options.name - The name of the type
       * @param {Object.<String, ParameterInfo>} parameters - The parameter definitions
       * @param {String} returnTypeName - The name of the return type
       * @return {void}
       */
      constructor({
        name,
        callableName,
        parameters,
        returnTypeName,
      }) {
        super({
          name,
          callableName,
          parameters,
          returnTypeName,
        });
      }
    }

    /**
     * @class FunctionType
     * @property {Parameter[]} parameters - The parameters required to call the callable
     * @property {String} returnTypeName - The name of the return type
     * @property {Type} returnType - The return type of the callable
     */
    class FunctionType extends CallableType {
      /**
       * Create a CallableType
       * @param {Object} options - The constructor options
       * @param {String} options.name - The name of the type
       * @param {Object.<String, ParameterInfo>} parameters - The parameter definitions
       * @param {String} returnTypeName - The name of the return type
       * @return {void}
       */
      constructor({
        name,
        callableName,
        parameters,
        returnTypeName,
      }) {
        super({
          name,
          callableName,
          parameters,
          returnTypeName,
        });
      }
    }

    // TODO: wewei, support EnumType

    defineConstProperty(types, 'Property', Property);
    defineConstProperty(types, 'Parameter', Parameter);
    defineConstProperty(types, 'Type', Type);
    defineConstProperty(types, 'PrimitiveType', PrimitiveType);
    defineConstProperty(types, 'OneOfType', OneOfType);
    defineConstProperty(types, 'ObjectType', ObjectType);
    defineConstProperty(types, 'ComplexType', ComplexType);
    defineConstProperty(types, 'EntityType', EntityType);
    defineConstProperty(types, 'CollectionType', CollectionType);
    defineConstProperty(types, 'CallableType', CallableType);
    defineConstProperty(types, 'ActionType', ActionType);
    defineConstProperty(types, 'FunctionType', FunctionType);

    return types;
  })());
};
