import { Observable } from '@bingads-webui-universal/observable';

export class CacheEvent extends Observable {
  constructor() {
    super((observer) => {
      this.observers.add(observer);
      return () => {
        this.observers.delete(observer);
      };
    });

    // eslint-disable-next-line no-undef
    this.observers = new Set();
  }

  notify(type, entity) {
    this.observers.forEach((ob) => {
      ob.next({
        type,
        entity,
      });
    });
  }
}
