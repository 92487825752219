/**
 * the Entity Data Model module
 */
import typesPlugin from './types-plugin';
import schemaPlugin from './schema-plugin';

export class EDM {
  constructor(options) {
    typesPlugin(this);
    if (options) {
      schemaPlugin(this, options);
    }
  }
}
