import _ from 'underscore';
import { I18nModel } from '@bingads-webui-universal/i18n-model';
import { toIANATimeZone, fromLegacyTimeZone } from '@bingads-webui-universal/time-zone-converter';
import { getFailFastSetting } from './fail-fast-setting';

const buildStringsMap = legacyKeys => _.extend(
  {},
  ...(_.flatten([legacyKeys]))
);


const buildLegacyStrings =
  (i18n, allStrings) => _.mapObject(buildStringsMap(allStrings), str => i18n.getString(str));

/**
 * buildLegacyI18n
 * @param {object} i18n translation object
 * @param {*} legacyKeys array of key to _TL_ strings
 * @param {*} param2 extra options
 * @returns {object} built legacy i18n
 */
export const buildLegacyI18n = (i18n, legacyKeys, {
  timeZone,
  currency,
} = {}) =>
  // rely on global locale
  new I18nModel({
    timeZone: _.isNumber(timeZone) ?
      toIANATimeZone(fromLegacyTimeZone(timeZone)) :
      undefined,
    currency,
    loadTranslationData: () => buildLegacyStrings(i18n, legacyKeys),
    loadCLDRData(locale) { return locale; },
    failFast: getFailFastSetting(),
  });
