export default function (instrumentation) {
  const logError = function (error) {
    if (instrumentation && instrumentation.logError) {
      instrumentation.logError(error.message);
    } else {
      console.error(error.message); // eslint-disable-line no-console
    }
  };

  const LocalStorage = {
    supportsStorage() {
      try {
        return window && 'localStorage' in window && window.localStorage != null;
      } catch (err) {
        logError(err);
        return false;
      }
    },
    set(key, data) {
      const str = JSON.stringify(data);
      try {
        window.localStorage.setItem(key, str);
      } catch (err) {
        try {
          window.localStorage.clear();
          window.localStorage.setItem(key, str);
        } catch (innerErr) {
          logError(innerErr);
        }
      }
    },
    get(key) {
      let result = null;
      try {
        const str = window.localStorage.getItem(key);
        if (str) {
          result = JSON.parse(str);
        }
      } catch (err) {
        logError(err);
      }
      return result;
    },
    invalidate(key) {
      try {
        window.localStorage.removeItem(key);
      } catch (err) {
        logError(err);
      }
    },
    clear() {
      try {
        window.localStorage.clear();
      } catch (err) {
        logError(err);
      }
    },
    keys() {
      return Object.getOwnPropertyNames(window.localStorage);
    },
    type: 'localStorage',
  };
  return LocalStorage;
}
