import _ from 'underscore';
import { required } from './validators/required';
import * as string from './validators/string';
import * as number from './validators/number';
import { enumeration } from './validators/enum';
import * as array from './validators/array';
import { passive } from './validators/passive';
import { custom } from './validators/custom';

function run(value, validators) {
  const failed = _.find(validators, v => !v.isValid(value));
  return failed ? failed.message : undefined;
}

export const Validation = {
  // validators
  required,
  string,
  number,
  enum: enumeration,
  array,
  passive,
  custom,
  // end of validators
  run, // run validation manually, for using without knockout
};
