import _ from 'underscore';
import { Base } from './base';

/**
 * Define validator which check whether is empty when field is Required
 * @param {boolean} allowSpace - whether allow space in value endpoint
 * @param {I18n} i18n - provide getString callback, will be called
 * with args 'Validation_NotEmpty_Required_Field
 */
class Required extends Base {
  constructor(allowSpace, i18n) {
    super(i18n);

    this.allowSpace = !!allowSpace;
    this.message = i18n.getString('Validation_NotEmpty_Required_Field');
  }

  isValidValue(value) {
    return _.isFinite(value) ||
      !_.isEmpty(!this.allowSpace && _.isString(value) ? value.trim() : value);
  }
}

/**
 * Create a required validator instance
 * @param {boolean} allowSpace - whether allow space in value endpoint
 * @param {I18n} i18n - provide getString callback
 * @returns {Validator} - required validator instance
 */
export function required(allowSpace, i18n) {
  return new Required(allowSpace, i18n);
}
