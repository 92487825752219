import * as putil from '@bingads-webui-universal/primitive-utilities';
import ajax from './odata-core';
import * as multipart from '../util/multipart';
import { ODataBatchOptions, Callback, ODataOptions } from '../types';

export default function batch(options: ODataBatchOptions, cb: Callback) {
  const boundary = putil.generateGuid();
  // NOFIX:
  // const url = config.get('odata.batch_url');
  const url = options.batchURL;

  // NOFIX:
  // if (!options.host) {
  //   options.host = 'odata.url';
  // }

  // if (!options.path) {
  //   options.path = 'odata.path';
  // }

  return ajax<ODataOptions<string>, string>(url, {
    method: 'POST',
    data: multipart.generate(options, boundary),
    contentType: `multipart/mixed; boundary="${boundary}"`,
    dataType: 'text',
    host: options.host,
    path: options.path,
    headers: options.headers,
    // NOTICE: oDataToken, loginType, SessionId are deprecated, use headers
  }, (err, response, textStatus, xhr) => {
    const res = err ? [] : multipart.parse(response);

    if (cb) {
      cb(err, res, textStatus, xhr);
    }
  })
    .then(multipart.parse);
}
