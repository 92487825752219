import _ from 'underscore';
import * as putil from '@bingads-webui-universal/primitive-utilities';
import { Base } from './base';
import { Size as NumberSize, Range as NumberRange } from './number';

/**
 * Define a validator check type of input, whether the string can convert to a validate number
 * @param {I18n} i18n - provide a callback getString, will be called
 * with args 'Validation_Number_Require_Numeric'
 */
class Type extends Base {
  constructor(i18n) {
    super(i18n);

    this.message = i18n.getString('Validation_Number_Require_Numeric');
  }

  isValidValue(value) {
    return (_.isString(value) && _.isEmpty(value)) || this.i18n.isValidDecimal(value, true);
  }
}

/**
 * Define a validator check size of string for string number
 * @param {int} integerLength - the length of int
 * @param {int} decimalLength - the length of decimal
 * @param {I18n} i18n - i18n will be called in class number/Size
 */
class Size extends NumberSize {
  isValidValue(value) {
    if (_.isString(value) && _.isEmpty(value)) {
      return true;
    }

    if (!this.i18n.isValidDecimal(value, true)) {
      return false;
    }

    const number = this.i18n.parseDecimal(value);

    if (putil.isNil(number)) {
      return false;
    }

    return super.isValidValue(number);
  }
}

/**
 * Define a validator check string number Range
 * @param {int|float} min - min value of input
 * @param {int|float} max - max value of input
 * @param {I18n} i18n - will be called in class number/Range
 */
class Range extends NumberRange {
  isValidValue(value) {
    if (_.isString(value) && _.isEmpty(value)) {
      return true;
    }

    if (!this.i18n.isValidDecimal(value, true)) {
      return false;
    }

    return super.isValidValue(this.i18n.parseDecimal(value));
  }
}

/**
 * Create string number type validate instance
 * @param {I18n} i18n - provide callback getString to get error message
 * @returns {Validator} - string number type validator
 */
export function type(i18n) {
  return new Type(i18n);
}

/**
 * Create a string number size validator instance
 * @param {int} integerLength - the length of int
 * @param {int} decimalLength - the length of decimal
 * @param {I18n} i18n - will be called in class number/Range
 * @returns {Validator} - string number size validator
 */
export function size(integerLength, decimalLength, i18n) {
  return new Size(integerLength, decimalLength, i18n);
}

/**
 * Create string number range validator instance
 * @param {int|float} min - min value of input
 * @param {int|float} max - max value of input
 * @param {I18n} i18n - will be called in class number/Range
 * @returns {Validator} - string number range validator
 */
export function range(min, max, i18n) {
  return new Range(min, max, i18n);
}
