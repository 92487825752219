import _ from 'underscore';
import * as core from './core';
import ajax from './odata-core';
import batch from './odata-batch';
import {
  Args, ODataOptions, ODataUploadOptions, ODataMethod,
} from '../types';

export function defaults(defaultOptions: ODataOptions<any>): ODataMethod {
  return <Options extends ODataOptions<TResult>, TResult>(...args: Args<Options, TResult>) => {
    const [url, options, cb] = core.signature(args);

    const op = _.defaults({}, options, defaultOptions);

    return ajax<Options, TResult>(url, op, cb);
  };
}

export const get = defaults({ method: 'GET' });
export const put = defaults({ method: 'PUT' });
export const patch = defaults({ method: 'PATCH' });
export const $delete = defaults({ method: 'DELETE' });
export { ajax, batch };

export function post<Options extends ODataOptions<TResult>, TResult>(...args: Args<Options, TResult>) {
  const [url, options, cb] = core.signature(args);

  const op = _.defaults({}, options, {
    method: 'POST',
    xhr() {
      const xhr = core.xhr();

      if (options.progress && xhr.upload) {
        xhr.upload.addEventListener('progress', options.progress, false);
      }

      return xhr;
    },
  });

  return ajax<Options, TResult>(url, op, cb);
}

// upload using FormData with content-type multipart/form-data.
// FormData is not avaiable until IE 10.
export function upload<Options extends ODataUploadOptions<TResult>, TResult>(...args: Args<Options, TResult>) {
  const [url, options, cb] = core.signature<Options, TResult>(args);

  const op = _.defaults({}, options, {
    data: new FormData(),
    contentType: false,
    processData: false,
  });

  op.data.append('file', options.file);

  return post<Options, TResult>(url, op, cb);
}
