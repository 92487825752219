import _ from 'underscore';
import Promise from 'bluebird';
import {
  hasOwnProperty,
  defineConstProperty,
} from '@bingads-webui/reflection';
import {
  serialize,
  parse,
  populateIds,
  hasReadOnlyFields,
  getAttrsToSerialize,
  extractEntityErrors,
  buildDestroyObject,
} from '@bingads-webui/odata-bulk-upsert';
import { odata } from '@bingads-webui/http-util';
import { merge } from '@bingads-webui-universal/primitive-utilities';
import { join } from '@bingads-webui/url-util';
import { getSkipEntityCallPaths } from './constants';

function getCast(options) {
  return (options && !_.isEmpty(options.cast)) ? (`/${options.cast}`) : '';
}

function getParent(options) {
  return (options && !_.isEmpty(options.parent)) ? (`${options.parent}/`) : '';
}

export function odataBulkUpsertPlugin(edm, {
  ServiceRoot,
  AccountID,
  AdvertiserCustomerID,
  bulkUpsertSchema,
} = {}, {
  processOptions = _.identity,
} = {}) {
  if (hasOwnProperty(edm, 'odata.bulkUpsert')) {
    return;
  }

  function getUrl({ path, options }) {
    if (path) {
      const prefix = path.match(/\/Customers\([0-9]+\)\/Accounts\([0-9]+\)/);
      if (!_.isEmpty(prefix)) {
        return join(ServiceRoot, `${prefix[0]}/${getParent(options)}Default.BulkUpsert${getCast(options)}`);
      }
    }
    return join(ServiceRoot, `/Customers(${AdvertiserCustomerID})/Accounts(${AccountID})/${getParent(options)}Default.BulkUpsert${getCast(options)}`);
  }

  function invalidateCache(entityName, ids, skipEntityCallPaths = null) {
    if (edm['odata.cache']) {
      edm['odata.cache'].invalidateTypeCache(entityName, null, '', skipEntityCallPaths);
      _.each(ids, id => edm['odata.cache'].invalidateTypeCache(entityName, id, '', skipEntityCallPaths));
    }
  }

  function checkParsedResult(errors, result, entityName, ids, skipEntityCallPaths) {
    if (!_.isEmpty(errors)) {
      return Promise.reject({ // eslint-disable-line prefer-promise-reject-errors
        entity: result,
        errors,
      });
    }

    invalidateCache(entityName, ids, skipEntityCallPaths);

    return result;
  }

  _.each(bulkUpsertSchema, (bSchema, name) => {
    merge(edm.schema.schemas[`Model/${name}`], bSchema);
  });

  defineConstProperty(edm, 'odata.bulkUpsert', (() => {
    const {
      ResourceIdentifier,
    } = edm.resourceIdentifiers;

    ResourceIdentifier.prototype.bulkUpdate = function bulkUpdate(attrs, options = {}) {
      const { schema } = this.type;

      if (options.batch) {
        if (_.isUndefined(attrs.reqs)) {
          throw new Error('requests are required when batch bulkupserts.');
        }

        const reqs = _.map(attrs.reqs, (param) => {
          const csvRows = serialize(schema, { Id: attrs.Id, ...param.data });
          const uri = getUrl({ path: this.path, options });

          return {
            type: 'POST',
            url: uri,
            data: { CsvRows: csvRows },
          };
        });

        return odata.batch(_.defaults({ reqs, path: options.path }, options)).then(responses => (
          Promise.map(responses, (response, index) => {
            const { results, errors } = parse(response.data);
            const ret = populateIds(results, attrs.reqs[index].data);

            return checkParsedResult(
              errors,
              ret,
              options.entityName || this.type.name,
              [attrs.Id || attrs.reqs[0].data.Id]
            );
          })
        ))
          .then(parsedResponse => (_.isFunction(options.processResponse)
            ? options.processResponse(parsedResponse)
            : parsedResponse));
      }

      const toSerialize = getAttrsToSerialize(attrs.Id, attrs, schema);
      const CsvRows = serialize(schema, toSerialize);

      return odata.post(getUrl({ path: this.path, options }), processOptions({ data: { CsvRows } }))
        .then((res) => {
          const { results, errors } = parse(res);
          const ret = populateIds(results, attrs);

          if (results && results.length && hasReadOnlyFields(attrs, schema.properties)) {
            ret.Id = results[0].Id;
          }

          return checkParsedResult(errors, ret, options.entityName || this.type.name, [attrs.Id]);
        });
    };

    ResourceIdentifier.prototype.bulkUpdateMany = function bulkUpdateMany(
      idList,
      attrsList,
      options = {}
    ) {
      const { schema } = this.type;
      const toSerialize = _.map(
        idList,
        (id, index) => getAttrsToSerialize(id, attrsList[index], schema)
      );
      const CsvRows = serialize(schema, toSerialize);

      return odata
        .post(getUrl({ path: this.path, options }), _.defaults(processOptions({ data: { CsvRows } }), options))
        .then((res) => {
          const { results, errors } = parse(res);
          const attrsListWithIds = populateIds(results, attrsList);

          invalidateCache(options.entityName || this.type.name, idList);

          return _.map(attrsListWithIds, (attrs, index) => ({
            entity: attrs,
            errors: extractEntityErrors(errors, index),
          }));
        });
    };

    ResourceIdentifier.prototype.bulkCreate = function bulkCreate(attrs, options = {}) {
      const { schema } = this.type;
      const CsvRows = serialize(schema, attrs);

      return odata
        .post(getUrl({ path: this.path, options }), _.defaults(processOptions({ data: { CsvRows } }), options))
        .then((res) => {
          const { results, errors } = parse(res);
          const ret = populateIds(results, attrs);

          return checkParsedResult(errors, ret, options.entityName || this.type.name, [null]);
        });
    };

    ResourceIdentifier.prototype.bulkCreateMany = function bulkCreateMany(attrsList, options = {}) {
      const { schema } = this.type;
      const CsvRows = serialize(schema, attrsList);

      return odata
        .post(getUrl({ path: this.path, options }), _.defaults(processOptions({ data: { CsvRows } }), options))
        .then((res) => {
          const { results, errors } = parse(res);
          const attrsListWithIds = populateIds(results, attrsList);

          invalidateCache(options.entityName || this.type.name);

          return _.map(attrsListWithIds, (attrs, index) => ({
            entity: attrs,
            errors: extractEntityErrors(errors, index),
          }));
        });
    };

    ResourceIdentifier.prototype.bulkDestroy = function bulkDestroy(id, options = {}) {
      const { schema } = this.type;
      const attrs = buildDestroyObject(schema.parent, id, options.parentId);
      const CsvRows = serialize(schema, attrs);

      return odata
        .post(getUrl({ path: this.path, options }), _.defaults(processOptions({ data: { CsvRows } }), options))
        .then((res) => {
          const { results, errors } = parse(res);
          const ret = populateIds(results, attrs);
          const entityName = options.entityName || this.type.name;

          return checkParsedResult(errors, ret, entityName, [id], getSkipEntityCallPaths(entityName, [id]));
        });
    };

    // put the plugin indicator
    return {};
  })());
}
