import _ from 'underscore';
import { I18nModel } from '@bingads-webui-universal/i18n-model';
import { getFailFastSetting } from './fail-fast-setting';

const mergeLegacyStrings = legacyI18ns => _.reduce(legacyI18ns, (legacyStrings, i18n) => ({
  ...legacyStrings,
  ...i18n.getData(),
}), {});

export const mergeLegacyI18ns = (legacyI18ns) => {
  // All i18n passed should logically use the same timeZone and currency
  const { timeZone, currency } = legacyI18ns[0];
  return new I18nModel({
    timeZone,
    currency,
    loadTranslationData: () => mergeLegacyStrings(legacyI18ns),
    loadCLDRData(locale) { return locale; },
    failFast: getFailFastSetting(),
  });
};
