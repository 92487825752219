import _ from 'underscore';
import { Base } from './base';

/**
 * Define a type validator which check the value type is an array
 * @param {I18n} i18n - provide callback getString to get error message for Base
 */
class Type extends Base {
  isValidValue(value) {
    return _.isArray(value);
  }
}

/**
 * Define a size validator which check the size of array between min and max
 * @param {int} min - the min size of array, default be 0
 * @param {int} max - the max size of array
 * @param {I18n} i18n - provide callback getString to get error message when validate failed, will
 * be callled with args 'Validation_Array_Size_Between' or 'Validation_Array_Size_Max'
 */
class Size extends Base {
  constructor(min, max, i18n) {
    super(i18n);

    this.min = min || 0;
    this.max = max;
    if (this.min) {
      this.message = i18n.getString('Validation_Array_Size_Between', { min: this.min, max });
    } else {
      this.message = i18n.getString('Validation_Array_Size_Max', { max });
    }
  }

  isValidValue(value) {
    const valueSize = _.size(value);

    return _.isArray(value) && valueSize >= this.min && valueSize <= this.max;
  }
}

function passAll(validators, value) {
  return _.every(validators, validator => validator.isValid(value));
}

function sizeNotFits(value, validators, additionalValidators) {
  return !additionalValidators && _.size(value) > _.size(validators);
}

/**
 * Create an array validator which inspects each item of an array by validators passed in.
 * @param {Validator[]} validators - validators for all items, each item should pass all validators.
 * @param {I18n} i18n - provide callback getString to get error message, will be called with args
 * 'Validation_Base_Field_Not_Valid' or 'Validation_Array_Items_Invalid'
 */
class Item extends Base {
  constructor(validators, i18n) {
    super(i18n);

    this.validators = validators;
    this.message = (value) => {
      if (!_.isArray(value)) {
        return i18n.getString('Validation_Base_Field_Not_Valid');
      }

      return i18n.getString('Validation_Array_Items_Invalid');
    };
  }

  /**
   * override function, check whether value is valid
   * @param {array} value - value to validate
   * @return {boolean} - whether value is valid
   */
  isValidValue(value) {
    return _.isArray(value) && _.isEmpty(this.invalidItems(value));
  }

  /**
   * Filter out invalid items.
   * @param {array} value - value to verify
   * @return {array} - return invalidate items
   * @see http://json-schema.org/latest/json-schema-validation.html#anchor37 for understanding conditions of successful validation.
   */
  invalidItems(value) {
    return _.omit(value, _.partial(passAll, this.validators));
  }
}

/**
 * Create an array validator which inspects items of an array.
 * @param {Validator[]} validators - validators for first _.size(validator) items.
 * @param {(Validator[]|boolean)} additionalValidators - boolean as allowing or
 * not additional items, Validator[] as validators for additional items.
 * @param {I18n} i18n - provide callback getString to get error message
 */
class Items extends Base {
  constructor(validators, additionalValidators, i18n) {
    super(i18n);

    this.validators = validators;
    this.message = (value) => {
      if (!_.isArray(value)) {
        return i18n.getString('Validation_Base_Field_Not_Valid');
      }

      if (sizeNotFits(value, validators, additionalValidators)) {
        return i18n.getString('Validation_Array_Size_Max', { max: _.size(validators) });
      }

      return i18n.getString('Validation_Array_Items_Invalid');
    };
  }

  isValidValue(value) {
    return _.isArray(value) && _.isEmpty(this.invalidItems(value));
  }

  /**
   * Filter out invalid items.
   *
   * when additionalValidators is false, means max length of array is _.size(validators);
   * when additionalValidators is true, no validation for additional items;
   * when additionalValidators is Validator[], it will be applied to additional
   * items not covered by validators.
   * @param {array} value - value to verify
   * @return {array} - return invalid items
   * @see http://json-schema.org/latest/json-schema-validation.html#anchor37 for understanding conditions of successful validation.
   */
  invalidItems(value) {
    const { validators } = this;
    let { additionalValidators } = this;

    if (sizeNotFits(value, validators, additionalValidators)) {
      return value;
    }

    // additionalValidator could be validators or boolean
    if (additionalValidators === true) {
      additionalValidators = [];
    }

    return _.omit(value, (val, index) => {
      const vs = validators[index] || additionalValidators;
      return passAll(vs, val);
    });
  }
}

/**
 * Create an array validator unique, check whether element unique in array
 * @param {I18n} i18n - provide callback getString, will be called
 * with args 'Validation_Array_Items_Duplicate'
 */
class Unique extends Base {
  constructor(i18n) {
    super(i18n);

    this.message = i18n.getString('Validation_Array_Items_Duplicate');
  }

  isValidValue(value) {
    return _.isArray(value) && _.size(_.uniq(value)) === _.size(value);
  }
}

/**
 * Create array type validate instance
 * @param {I18n} i18n - provide callback getString to get error message
 * @return {Validator} - array type validator
 */
export function type(i18n) {
  return new Type(i18n);
}

/**
 * Create size validate instance
 * @param {int} min - the min size of array
 * @param {int} max - the max size of array
 * @param {I18n} i18n - provide callback getString to get error message
 * @returns {Validator} - array size validator
 */
export function size(min, max, i18n) {
  return new Size(min, max, i18n);
}

/**
 * Create item validate instance
 * @param {Validator[]} validators - validators for all items, each item should pass all validators.
 * @param {I18n} i18n - provide callback getString to get error message, will be called with args
 * 'Validation_Base_Field_Not_Valid' or 'Validation_Array_Items_Invalid'
 * @returns {Validator} - array item validator
 */
export function item(validators, i18n) {
  return new Item(validators, i18n);
}

/**
 * Create an array items validator instance
 * @param {Validator[]} validators - validators for first _.size(validator) items.
 * @param {(Validator[]|boolean)} additionalValidators - boolean as allowing or
 * not additional items, Validator[] as validators for additional items.
 * @param {I18n} i18n - provide callback getString to get error message
 * @returns {Validator} - array items validator
 */
export function items(validators, additionalValidators, i18n) {
  return new Items(validators, additionalValidators, i18n);
}

/**
 * Create an array unique validate instance
 * @param {I18n} i18n - provide callback getString, will be called
 * with args 'Validation_Array_Items_Duplicate'
 * @returns {Validator} - array unique validator
 */
export function unique(i18n) {
  return new Unique(i18n);
}
