import _ from 'underscore';
import { defineConstProperty } from '@bingads-webui/reflection';
import { Registry } from '@bingads-webui/registry';

export const ONEOF_TYPE_PREFIX = '@ONEOF';
const COLLECTION_TYPE_POSTFIX = '@COLL';

function isOneOfType(typeName) {
  return typeName.indexOf(ONEOF_TYPE_PREFIX) === 0;
}

function hasPostfix(str, postfix) {
  return str.slice(-postfix.length) === postfix;
}

function removePostfix(str, postfix) {
  return str.slice(0, -postfix.length);
}

export function createResolveType(oneOfTypes, types, OneOfType) {
  return function resolveType(name, namespace) {
    if (hasPostfix(name, COLLECTION_TYPE_POSTFIX)) {
      return resolveType(removePostfix(name, COLLECTION_TYPE_POSTFIX), namespace).collectionType;
    }

    if (isOneOfType(name)) {
      if (!oneOfTypes.has(name)) {
        const prefixLength = ONEOF_TYPE_PREFIX.length + 1;
        const typeNames = name.substr(prefixLength, name.length - prefixLength - 1).split(',');
        // eslint-disable-next-line no-use-before-define
        oneOfTypes.set(name, new OneOfType({ typeNames }));
      }

      return oneOfTypes.get(name);
    }

    return types.resolve(name, namespace);
  };
}

/**
 * @typedef {Object} ParameterInfo
 * @property {String} typeName - The name of the parameter's type
 *
 * @memberof CallableType#
 * @this CallableType
 * @param {Object.<String, ParameterInfo>} parameters - The parameters to compile
 * @param {Object.Parameter} Parameter - The Parameter object
 * @return {Object.<String, Parameter>} A mapping for name to compiled Parameters
 */
export function compileParameters(parameters, Parameter = Object) {
  const { namespace } = this;

  return _.mapObject(parameters, ({ typeName }, name) =>
    new Parameter({ name, namespace, typeName }));
}

/**
 * @typedef {Object} PropertyInfo
 * @property {String} typeName - The name of the propertie's type
 *
 * @memberof ObjectType#
 * @this ObjectType
 * @param {Object.<String, PropertyInfo>} properties - The properties to be compiled
 * @param {Object.Property} Property - The Property object
 * @return {Object.<String, Property>} a mapping for name to compiled Properties
 */
export function compileProperties(properties, Property = Object) {
  const { namespace } = this;

  return _.mapObject(properties, ({
    typeName,
  }, name) =>
    new Property({
      name,
      typeName,
      namespace,
    }));
}

export function createType(types) {
/**
 * @class Type
 * @property {String} name - The qualified name of the type
 * @property {String} namespace - The namespace of the type
 * @property {String} shortName - The short name of the type
 */
// eslint-disable-next-line no-unused-vars
  return class Type {
    /**
     * Create and register a type
     * A side effect of Type creation is registering itself to the type registry
     * @param {Object} options - The constructor options
     * @param {String} options.name - The name of the type
     * @return {void}
     */
    constructor({
      name,
    }) {
      _.chain(this)
        .defineConstProperty('name', name)
        .defineConstProperty('namespace', Registry.getNamespace(name))
        .defineConstProperty('shortName', Registry.getShortName(name))
        .value();
      types.register(this);
    }
  };
}

export function createPrimitiveType(Superclass = Object) {
  /**
   * @class PrimitiveType
   * @extends Type
   * @property {Class} jsType - The corresponding JavaScript type
   */
  return class PrimitiveType extends Superclass {
    /**
     * Create a PrimitiveType
     * @param {Object} options - The constructor options
     * @param {String} options.name - The name of the type
     * @param {Class} options.jsType - The JavaScript type of the primitive type
     * @return {void}
     */
    constructor({
      name,
      jsType,
    }) {
      super({ name });
      defineConstProperty(this, 'jsType', jsType);
    }
  };
}


export function createProperty(resolveType) {
  /**
   * @class Property
   * @property {String} name - The name of the property
   * @property {String} typeName - The name of the property's type
   * @property {Type} type - The type of the property
   */
  return class Property {
    /**
     * Create a property
     * @param {Object} options - The constructor options
     * @param {String} options.name - The name of the property
     * @param {String} options.typeName - The name of the propertie's type
     * @param {String} options.namespace - The namespace of the propertie's type
     * @return {void}
     */
    constructor({
      name,
      typeName,
      namespace,
    }) {
      _.chain(this)
        .defineConstProperty('name', name)
        .defineConstProperty('typeName', typeName)
        .defineProducedProperty('type', () => resolveType(typeName, namespace))
        .value();
    }
  };
}

export function createParameter(resolveType) {
  /**
   * @class Parameter
   * @property {String} name - The name of the parameter
   * @property {String} typeName - The name of the paramter's type
   * @property {type} type - The type of the parameter
   */
  return class Parameter {
    /**
     * Create a parameter
     * @param {Object} options - The constructor options
     * @param {String} options.name - The name of the parameter
     * @param {String} options.typeName - The name of the parameter's type
     * @param {String} options.namespace - The namespace of the parameter's type
     * @return {void}
     */
    constructor({
      name,
      typeName,
      namespace,
    }) {
      _.chain(this)
        .defineConstProperty('name', name)
        .defineConstProperty('typeName', typeName)
        .defineProducedProperty('type', () => resolveType(typeName, namespace))
        .value();
    }
  };
}

export function createCollectionType(Superclass = Object, resolveType) {
  /**
   * @class CollectionType
   * @extends ObjectType
   * @property {String} elementTypeName - The name of the element type
   * @property {Type} elementType - The type of the elements
   */
  return class CollectionType extends Superclass {
    /**
     * Create a CollectionType
     * @param {Object} options - The constructor options
     * @param {Object.<String, PropertyInfo>} options.properties - The property definition
     * @param {String} options.baseTypeName - The name of the base type
     * @param {String} options.elementTypeName - The name of the element type
     * @return {void}
     */
    constructor({
      properties,
      baseTypeName,
      elementTypeName,
    }) {
      super({
        name: CollectionType.collectionTypeName(elementTypeName),
        properties,
        baseTypeName,
      });
      _.chain(this)
        .defineConstProperty('elementTypeName', elementTypeName)
        .defineProducedProperty('elementType', () => resolveType(this.elementTypeName, this.namespace))
        .value();
    }

    /**
     * Get the CollectionType's name from it's element type name
     * @param {String} typeName - The name of the elementType
     * @return {String} The name of the CollectionType
     */
    static collectionTypeName(typeName) {
      return `${typeName}${COLLECTION_TYPE_POSTFIX}`;
    }
  };
}
