import _ from 'underscore';
import { cloneDeep } from '@bingads-webui-universal/primitive-utilities';

const primitiveTypes = [
  'string',
  'number',
  'integer',
  'boolean',
  'datetime',
];

// const assumes that there is only one conversion
const conversionAlias = 'propertyConversion';

// Map enum value like 'Eligible' For DeliveryStatus to "Enum.DeliveryStatus'Eligible'"
export function mapEnumValueForFilter(enumType, enumValue) {
  return `Enum.${enumType}'${enumValue}'`;
}

export function buildNameToPropertyMapping(entitySchema, mapOnlyBaseProperty) {
  // TODO 893787 Provide generic support for customizable entity schema to filter property mapping
  if (mapOnlyBaseProperty) {
    return _.mapObject(entitySchema.properties, propObj => _.last(propObj.property.split('/')));
  }
  return _.mapObject(entitySchema.properties, propObj => propObj.property);
}

function restoreToEntitySchema(flattenedEntity) {
  const properties = _.reduce(flattenedEntity.fields, (memo, field) => {
    // eslint-disable-next-line no-param-reassign
    memo[field.name] = _.defaults({
      filterable: true,
    }, field);

    return memo;
  }, {});

  return _.defaults({ properties }, _.pick(flattenedEntity, 'name', 'type', 'operators'));
}

// Any function that deals with property conversion uses
// the assumption there is only one propertyAlternation
// In another word only one convesion is allowed
const hasConversionAlias = alias => _.has(alias, conversionAlias);

function flattenSchemaToFields(entitySchema) {
  const fields = [];
  const nameStack = [];

  function buildFilters(schema) {
    _.each(schema.properties, (prop, name) => {
      const parentNameStack = [...nameStack];
      nameStack.push(name);
      const property = nameStack.join('/');
      const propertyName = nameStack.join('');
      if (prop.type === 'object' && prop.schema && (prop.filterable !== false)) {
        // do not use original schema to not break it
        const schemaToBuild = cloneDeep(prop.schema);

        // did not build options for ones not filterable within an object
        if (_.isArray(prop.filterable)) {
          schemaToBuild.properties = _.pick(schemaToBuild.properties, prop.filterable);

          // add filterable to those properties in case they don't have them
          _.each(schemaToBuild.properties, (subPro, key) => {
            subPro.filterable = true;// eslint-disable-line no-param-reassign

            if (_.has(prop.alias, key)) {
              subPro.alias = prop.alias[key];// eslint-disable-line no-param-reassign
            }
          });
        }
        buildFilters(schemaToBuild);
      } else if (_.contains(primitiveTypes, prop.type) && prop.filterable) {
        const baseSchema = {
          name: propertyName,
          type: prop.type,
          property,
          isPercentValue: prop.isPercentValue,
          skipLocalization: prop.skipLocalization,
          operators: prop.operators,
          nullable: prop.nullable,
          maxLength: prop.maxLength,
          originalProperty: _.last(nameStack),
          minimum: prop.minimum,
          maximum: prop.maximum,
          required: true,
          pattern: prop.pattern,
          localized: prop.localized,
          isDeletedProperty: prop.isDeletedProperty,
          isInvalidProperty: prop.isInvalidProperty,
        };

        if (prop.customColumn) {
          baseSchema.name = name;
          baseSchema.title = prop.title;
          baseSchema.isPercentValue = prop.format === 'Percentage';
          baseSchema.id = prop.id;
          baseSchema.format = prop.format;
          baseSchema.customColumn = true;
        }

        if (prop.type === 'datetime') {
          baseSchema.hasTime = prop.hasTime;
        }

        // Include the custom operators' localization map
        if (prop.operatorDisplayNameMap) {
          baseSchema.operatorDisplayNameMap = prop.operatorDisplayNameMap;
        }

        // Keep the legacy column name conversion support.
        if (prop.alias) {
          const aliasCopy = cloneDeep(prop.alias);
          _.extend(baseSchema, { alias: aliasCopy });
          if (hasConversionAlias(aliasCopy)) {
            // input schema must provide sourcePropertyType and one
            // of parameters, sourceProperty, an odata short name, or
            // sourcePropertyName, a full name with its parentName
            const { sourceProperty, sourcePropertyType, sourcePropertyName } =
              aliasCopy.propertyConversion;


            if (!sourcePropertyType) {
              throw new Error('Must provide a sourcePropertyType');
            }
            if (!sourcePropertyName) {
              if (!sourceProperty) {
                throw new Error('Must provide a sourcePropertyName or a sourceProperty');
              }

              const aliasNameStack = [...parentNameStack, sourceProperty];
              const joinedSourceName = aliasNameStack.join('');

              baseSchema.alias.propertyConversion.sourcePropertyName = joinedSourceName;
            }
          }
        }

        // update settings for enum type
        if (!_.isEmpty(prop.enum)) {
          baseSchema.enumValueMap = {};
          _.extendOwn(baseSchema, { enum: [] }, _.pick(prop, 'chooseType', 'enumType'));

          const enumType = baseSchema.enumType ? baseSchema.enumType : baseSchema.name;

          // update enum value (for odata converting) & localization
          _.each(prop.enum, (enumValue) => {
            const mappedEnumValue = mapEnumValueForFilter(enumType, enumValue);

            baseSchema.enum.push(mappedEnumValue);
            baseSchema.enumValueMap[mappedEnumValue] = enumValue;
          });
        } else if (prop.type === 'boolean') {
          _.extendOwn(baseSchema, _.pick(prop, 'chooseType'));
        } else if (prop.type === 'string') {
          _.extendOwn(baseSchema, _.pick(prop, 'chooseType'));
          if (prop.customParameters) {
            _.extendOwn(baseSchema, _.pick(prop, 'customParameters'));
          }
        }

        fields.push(baseSchema);
      }
      nameStack.pop(name);
    });
  }

  buildFilters(entitySchema);

  return _.defaults({ fields }, _.pick(entitySchema, ['name', 'type']));
}

export function flattenEntitySchema(entitySchema) {
  return restoreToEntitySchema(flattenSchemaToFields(entitySchema));
}
