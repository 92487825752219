(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("underscore"), require("@aria/webjs-sdk"), require("@bingads-webui/listener"));
	else if(typeof define === 'function' && define.amd)
		define(["underscore", "@aria/webjs-sdk", "@bingads-webui/listener"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("underscore"), require("@aria/webjs-sdk"), require("@bingads-webui/listener")) : factory(root["underscore"], root["@aria/webjs-sdk"], root["@bingads-webui/listener"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})((typeof self !== 'undefined' ? self : this), function(__WEBPACK_EXTERNAL_MODULE__3__, __WEBPACK_EXTERNAL_MODULE__4__, __WEBPACK_EXTERNAL_MODULE__5__) {
return 