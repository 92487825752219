const prodEnvs = [
  'ads.microsoft.com',
  'ui.ads.microsoft.com',
  'ui.bingads.microsoft.com',
  'bingads.microsoft.com',
];

export const getFailFastSetting = () => {
  const hostname = window && window.location && window.location.hostname;
  if (hostname && prodEnvs.indexOf(hostname.toLowerCase()) > -1) {
    return 'SAFE';
  }
  return true;
};
