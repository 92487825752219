import { Base } from './base';

/**
 * Define passive validator
 * @param {I18n} i18n - provide callback getString to get message
 */
class Passive extends Base {
  constructor(i18n) {
    super(i18n);

    this.error = null;
  }

  /**
   * Check whether value is valid
   * @param {any} value - value to be checked
   * @returns {boolean} - if value is valid return true, otherwise, return false
   */
  isValid(value) {
    if (this.error && this.error.value === value) {
      return false;
    }
    this.error = null;
    return true;
  }

  /**
   * Set error value and correspond message
   * @param {function|string} message - message of error
   * @param {any} value - error value
   * @returns {undefined} - no returns
   */
  setError(message, value) {
    this.error = {
      message,
      value,
    };
    this.message = message;
  }
}

/**
 * Create passive validator instance
 * @param {I18n} i18n - provide callback getString to get message
 * @returns {Validator} - return passive validator instance
 */
export function passive(i18n) {
  return new Passive(i18n);
}
