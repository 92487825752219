import _ from 'underscore';

export function parse(json: string, cb: (err: Error | undefined, data?: any) => void) {
  try {
    cb(undefined, JSON.parse(json));
  } catch (err: any) {
    cb(err);
  }
}

export function parseSync(json: string) {
  try {
    return JSON.parse(json);
  } catch (err) {
    return undefined;
  }
}

export function stringifySync(obj: any) {
  try {
    return JSON.stringify(obj);
  } catch (err) {
    return '';
  }
}

type IndexArray = (string | number)[]
type Cycle = { inner: IndexArray, outer: IndexArray, str: string } | null

export function findCycle(obj?: any) {
  const stackObj = [obj];
  const stackKey: Array<string | number> = ['[obj]'];

  function step(innerObj: any) {
    let result: Cycle = null;
    const stackIndex = _.indexOf(stackObj, innerObj);

    if (stackIndex !== -1 && stackIndex !== stackObj.length - 1) {
      const inner = _.tail(stackKey, 0);
      const outer = _.head(stackKey, stackIndex + 1);
      return {
        inner,
        outer,
        str: `${inner.join('.')} === ${outer.join('.')}`,
      };
    }

    _.each(innerObj, (val, key) => {
      if (!!result || !_.isObject(val)) {
        return;
      }
      stackObj.push(val);
      stackKey.push(key);

      result = result || step(val);

      stackObj.pop();
      stackKey.pop();
    });
    return result;
  }

  return step(obj);
}
