/**
 * the reflection module for property defintions
 */
import _ from 'underscore';

/**
 * Define a const property
 * @param {Object} obj - the host object of the proprty
 * @param {String} name - the name of the property
 * @param {Object} value - the value of the property
 * @return {Object} the host object
 */
export function defineConstProperty<T extends {}>(obj: T, name: string, value: any): T {
  return Object.defineProperty(obj, name, {
    value,
    enumerable: true,
    writable: false,
  });
}

/**
 * Define a produced property with a factory
 * @param {Object} obj - the host object of the proprty
 * @param {String} name - the name of the property
 * @param {Function} factory - the factory to produce the property value
 * @return {Object} the host object
 */
export function defineProducedProperty<T extends {}>(obj: T, name: string, factory: (this: T) => any): T {
  return Object.defineProperty(obj, name, {
    get() {
      const value = factory.apply(obj);

      defineConstProperty(obj, name, value);
      return value;
    },
    enumerable: true,
    configurable: true,
  });
}

/**
 * Define a produced property on a class
 * @param {Class} Class - the host class of the proprty
 * @param {String} name - the name of the property
 * @param {Function} factory - the factory to produce the property value
 * @return {Class} the host class
 */
export function defineProducedPropertyOnClass<T extends {}, TClass extends { new(): T, name?: string }>(Class: TClass, name: string, factory: (this: T) => any): TClass {
  // TODO: wewei, use ES6 Symbol to implement this when babel-polyfill is ready
  const RAND_MAX = 65535;
  const className = Class.name || `Anony${_.random(0, RAND_MAX)}`;
  const symbol = `__${className}_${name}`;

  Object.defineProperty(Class.prototype, name, {
    get() {
      if (!Object.prototype.hasOwnProperty.call(this, symbol)) {
        defineConstProperty(this, symbol, factory.apply(this));
      }
      return this[symbol];
    },
    enumerable: true,
  });

  return Class;
}

/**
 * Define a computed property whose value is computed each time the getter being called
 * @param {Object} obj - the host object of the proprty
 * @param {String} name - the name of the property
 * @param {Function} getter - the getter to compute the property value
 * @return {Object} the host object
 */
export function defineComputedProperty<T extends {}>(obj: T, name: string, getter: () => any): T {
  return Object.defineProperty(obj, name, {
    get: getter,
    enumerable: true,
  });
}

// Make the functions chainable with underscorejs
_.mixin({
  defineConstProperty,
  defineProducedProperty,
  defineProducedPropertyOnClass,
  defineComputedProperty,
});

/**
 * Detect whether or not a object has certain property without evaluation
 * @param {Object} obj - the host class of the proprty
 * @param {String} name - the name of the property
 * @return {Boolean} whether the property is defined
 */
export function hasOwnProperty(obj: {}, name: string): boolean {
  return !!Object.getOwnPropertyDescriptor(obj, name);
}
