import _ from 'underscore';
import * as putil from '@bingads-webui-universal/primitive-utilities';
/**
 * Base validator class, provide public interface
 * @param {I18n} i18n - provide callback getString to get error message, will be called
 * with args 'Validation_Base_Field_Not_Valid'
 */
export class Base {
  constructor(i18n) {
    this.blockInput = false;
    this.nullable = false;
    this.i18n = i18n;
    this.message = i18n.getString('Validation_Base_Field_Not_Valid');
  }

  set(options = {}) {
    if (!_.isUndefined(options.blockInput)) {
      this.blockInput = options.blockInput;
    }

    if (!_.isUndefined(options.message)) {
      this.message = options.message;
    }

    if (!_.isUndefined(options.nullable)) {
      this.nullable = options.nullable;
    }

    return this;
  }

  /**
   * Base method to validate value
   * @param {any} value - value to check
   * @returns {boolean} - whether value is valid
   */
  isValid(value) {
    return (this.nullable && putil.isNil(value)) || this.isValidValue(value);
  }

  /**
   * Base method to validate value, if valid, return undefined, otherwise, return error message
   * @param {any} value - value to validate
   * @returns {undefined|string} - return undefined if valid, otherwise return error message
   */
  validate(value) {
    return this.isValid(value) ? undefined : this.message;
  }
}
