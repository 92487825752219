export default function (instrumentation) {
  const logError = function (error) {
    if (instrumentation && instrumentation.logError) {
      instrumentation.logError(error.message);
    } else {
      console.error(error.message); // eslint-disable-line no-console
    }
  };

  const SessionStorage = {
    supportsStorage() {
      return window && 'sessionStorage' in window && window.sessionStorage != null;
    },
    set(key, data) {
      const str = JSON.stringify(data);
      try {
        window.sessionStorage.setItem(key, str);
      } catch (err) {
        try {
          window.sessionStorage.clear();
          window.sessionStorage.setItem(key, str);
        } catch (innerErr) {
          logError(err);
        }
      }
    },
    get(key) {
      let result = null;
      try {
        const str = window.sessionStorage.getItem(key);
        if (str) {
          result = JSON.parse(str);
        }
      } catch (err) {
        logError(err);
      }
      return result;
    },
    clear() {
      try {
        window.sessionStorage.clear();
      } catch (err) {
        logError(err);
      }
    },
    invalidate(key) {
      try {
        window.sessionStorage.removeItem(key);
      } catch (err) {
        logError(err);
      }
    },
    type: 'sessionStorage',
  };

  return SessionStorage;
}
