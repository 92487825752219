import _ from 'underscore';
import Promise from 'bluebird';
import { getSessionStorage } from '@bingads-webui/storage';
import { cloneDeep } from '@bingads-webui-universal/primitive-utilities';
import { preferenceDS } from './data-store';

const Level = {
  All: 0,
  User: 1,
  Account: 2,
};

let isInitialized = false;
let localRepository = [];
let localAccountLevelRepository = [];
let localUserLevelRepository = [];
let p$preferences = null;
let keySessionStorage = null;

const localToRemoteIdMap = {};
const userLevelLocalToRemoteIdMap = {};
let latestLocalId = 0;
const resolvedPromise = Promise.resolve();
let remoteSyncTasks = resolvedPromise;

const SessionStorage = getSessionStorage();
let currentUserInfo;
let currentCustomerInfo;
let currentAccountInfo;
let APIHost;
let APIPath;
let oDataToken;

/**
 * Resolves when remote sync tasks is done. Will make use of
 * passed in ID after other optimizations, making it mandatory to pass
 * for that case.
 * @param {String} id - ID for data
 * @returns {Promise} - resolves when remote sync is done
 */
const onRemoteSyncDone = (id) => {
  if (!id) {
    throw new Error('Should pass the ID of the preference item to check');
  }

  return remoteSyncTasks;
};

/**
 * Resolves when remote sync tasks for multiple Ids is done. Will make use of
 * passed in IDs after other optimizations, making it mandatory to pass
 * for that case.
 * @param {String[]} ids - IDs for data
 * @returns {Promise} - resolves when remote sync is done
 */
const onMultipleRemoteSyncDone = ids => Promise.all(ids.map(id => onRemoteSyncDone(id)));

const getStoreOptions = (params, isUserLevel) => {
  const accountId = isUserLevel === true ? 0 : currentAccountInfo.Id;
  const customerId = currentCustomerInfo.Id;

  return {
    cacheResponse: false,
    entitySet: {
      odataURL: `/Customers(${customerId})/Accounts(${accountId})/Preferences`,
      $withKey: function withKey(id) {
        const odataURL = `${this.odataURL}(${id})`;
        return {
          odataURL,
        };
      },
    },
    afterUpdate: (resource, attrs) =>
      // OData Server should return the entity as response for PATCH
      // But it doesn't, so we hack here to use request body as the response
      Promise.resolve(_.defaults({}, params, attrs)),
    host: APIHost,
    path: APIPath,
    oDataToken,
  };
};

const concatLocalRepository = () => {
  if (_.isArray(localUserLevelRepository) && localUserLevelRepository.length > 0) {
    localRepository = localUserLevelRepository.concat(localAccountLevelRepository);
  } else {
    localRepository = localAccountLevelRepository;
  }

  return localRepository;
};

const getSessionStorageKey = () => {
  const userName = currentUserInfo.Name || 'anonymous';
  const customerId = currentCustomerInfo.Id;

  return `Preferences_${userName}_${customerId}`;
};

const parseData = (rawData) => {
  let parsedData = null;

  try {
    parsedData = JSON.parse(rawData);
  } catch (e) {
    parsedData = rawData;
  }

  return parsedData;
};

const mapPreferenceData = rawPreferenceData => _.map(rawPreferenceData, (result) => {
  result.Data = parseData(result.Data); // eslint-disable-line no-param-reassign

  return result;
});

const initSync = ({
  initialUserPreferences,
  currentUser,
  currentCustomer,
  currentAccount,
  apiHost,
  apiPath,
  authToken,
}) => {
  if (localRepository.length === 0) {
    currentUserInfo = currentUser;
    currentCustomerInfo = currentCustomer;
    currentAccountInfo = currentAccount;
    APIHost = apiHost;
    APIPath = apiPath;
    oDataToken = authToken;

    const userLevelPreferenceFromPageContext =
      parseData(initialUserPreferences.UserLevelPreferences);
    const accountLevelPreferenceFromPageContext =
      parseData(initialUserPreferences.AccountLevelPreferences);

    if (_.isArray(userLevelPreferenceFromPageContext)
        && _.isArray(accountLevelPreferenceFromPageContext)) {
      localUserLevelRepository = mapPreferenceData(userLevelPreferenceFromPageContext);
      localAccountLevelRepository = mapPreferenceData(accountLevelPreferenceFromPageContext);

      // successfully initialized with data from page context
      isInitialized = true;
      concatLocalRepository();
    }
  }

  return localRepository;
};

const initialize = (context) => {
  if (!p$preferences) {
    const {
      initialUserPreferences,
      currentUser,
      currentCustomer,
      currentAccount,
      apiHost,
      apiPath,
      authToken,
    } = context;

    const repository = initSync({
      initialUserPreferences,
      currentUser,
      currentCustomer,
      currentAccount,
      apiHost,
      apiPath,
      authToken,
    });

    if (isInitialized) {
      // repository has already been initialized, no need to call findAll()
      p$preferences = Promise.resolve().then(() => repository);
      return p$preferences;
    }

    currentUserInfo = currentUser;
    currentCustomerInfo = currentCustomer;
    currentAccountInfo = currentAccount;
    APIHost = apiHost;
    APIPath = apiPath;
    oDataToken = authToken;

    p$preferences = Promise.settle([
      preferenceDS.findAll({
        limit: Number.MAX_VALUE - 1,
        userLevel: false,
      }, getStoreOptions()),
      preferenceDS.findAll({
        limit: Number.MAX_VALUE - 1,
        userLevel: true,
      }, getStoreOptions({}, true)),
    ]).then((results) => {
      if (results[1].isFulfilled()) {
        const resultUserPreferences = results[1].value();

        if (_.isArray(resultUserPreferences)) {
          localUserLevelRepository = mapPreferenceData(resultUserPreferences);
        }
      }

      if (results[0].isFulfilled()) {
        const resultAccountPreferences = results[0].value();

        if (_.isArray(resultAccountPreferences)) {
          localAccountLevelRepository = mapPreferenceData(resultAccountPreferences);
        }
      }

      // successfully initialized with async odata fetch
      isInitialized = true;
      return concatLocalRepository();
    });
  }

  if (!keySessionStorage) {
    keySessionStorage = getSessionStorageKey();
  }

  return p$preferences;
};

const findByPrefix = (prefix) => {
  if (!prefix) {
    return [];
  }

  const searchPattern = new RegExp(`^${prefix.replace(/\./g, '\\.')}`, 'i');

  return cloneDeep(_.filter(localRepository, preference => searchPattern.test(preference.Name)));
};

const findByName = name => cloneDeep(_.findWhere(localRepository, { Name: name }));

const findByNameAtUserLevel =
  name => cloneDeep(_.findWhere(localUserLevelRepository, { Name: name }));

const findByNameAtAccountLevel =
  name => cloneDeep(_.findWhere(localAccountLevelRepository, { Name: name }));

const find = id => cloneDeep(_.findWhere(localRepository, { Id: id }));

const setToSession = (data) => {
  if (!data || !SessionStorage) {
    return Promise.resolve();
  }

  const sessionData = SessionStorage.get(keySessionStorage) || [];

  const preference = _.findWhere(sessionData, { Name: data.Name });

  if (_.isEmpty(preference)) {
    sessionData.push(data);
  } else {
    _.extend(preference, data);
  }

  SessionStorage.set(keySessionStorage, sessionData);

  return Promise.resolve(sessionData);
};

const findByNameInSession = (name) => {
  const sessionData = SessionStorage.get(keySessionStorage);

  return _.findWhere(sessionData, { Name: name });
};

const delFromSession = (name) => {
  if (!name || !SessionStorage) {
    return;
  }

  let sessionData = SessionStorage.get(keySessionStorage);

  sessionData = _.reject(sessionData, { Name: name });
  SessionStorage.set(keySessionStorage, sessionData);
};

/**
 * Generate a local ID for keys created in this window.
 * Once data is persisted to remote, it will be mapped to the
 * remote ID for subsequent syncing.
 * @returns {String} - ID for data
 */
const generateLocalId = () => {
  latestLocalId += 1;
  return `localId_${latestLocalId}`;
};

/**
 * Replaces the Id parameter with the remote Id if it
 * was previously mapped
 * @param {Object} params - The params object to save
 * @param {Any} id - The local Id to replace
 * @param {Boolean} isUserLevel - True if it is a user level preference
 * @returns {undefined} - no returns
 */
const setRemoteId = (params, id, isUserLevel) => {
  if (!_.isString(params.Id) || params.Id.indexOf('localId_') !== 0) {
    return;
  }
  const keyMap = isUserLevel ? userLevelLocalToRemoteIdMap : localToRemoteIdMap;

  params.Id = keyMap[id] || undefined; // eslint-disable-line no-param-reassign
};

/**
 * After a preference is destroyed, removes its ID mapping
 * @param {any} id - local ID
 * @param {any} isUserLevel - True if it is a user level preference
 * @returns {undefined} - no returns
 */
const deleteMappedId = (id, isUserLevel) => {
  const remoteIdMap = isUserLevel ? userLevelLocalToRemoteIdMap : localToRemoteIdMap;

  delete remoteIdMap[id];
};

/**
 * Adds a task for remote syncing to the queue
 * @param {Promise} task - Promise with some CUD operation
 * @returns {Promise} - The promise that will resolve once operation is done
 */
const addTask = (task) => {
  if (remoteSyncTasks !== resolvedPromise && remoteSyncTasks.isFulfilled()) {
    remoteSyncTasks = resolvedPromise;
  }
  remoteSyncTasks = remoteSyncTasks.then(task).catch(() => {
    // Remote call failed. Chain will continue;
  });
  return remoteSyncTasks;
};

const updateLocalCopy = (isUserLevel, id, data) => {
  const localCopy = isUserLevel === true ? localUserLevelRepository : localAccountLevelRepository;
  let preference = _.findWhere(localCopy, { Id: id });

  if (_.isEmpty(preference)) {
    preference = _.defaults({ Id: id }, data);
    localCopy.push(preference);
  } else {
    _.extend(preference, data);
  }

  concatLocalRepository();

  return cloneDeep(preference);
};

const createOrUpdate = (data, level) => {
  if (!isInitialized) {
    return Promise.resolve(null);
  }

  const isUserLevel = level === Level.User;
  let currentPreference = [];
  if (level === Level.All) {
    currentPreference = findByName(data.Name);
  } else if (level === Level.User) {
    currentPreference = findByNameAtUserLevel(data.Name);
  } else if (level === Level.Account) {
    currentPreference = findByNameAtAccountLevel(data.Name);
  }

  if (currentPreference
    && _.isEqual(currentPreference.Data, data.Data)) {
    return Promise.resolve(currentPreference);
  }

  if (currentPreference && !data.Id) {
    data.Id = currentPreference.Id; // eslint-disable-line no-param-reassign
  }

  const params = _.defaults({
    // Data is in type String in OData Server
    Data: _.isString(data.Data) ? data.Data : JSON.stringify(data.Data),
  }, _.defaults(data, {
    Type: 'string',
    Version: 1,
  }));

  const id = _.isNull(data.Id) || _.isUndefined(data.Id) ? generateLocalId() : data.Id;
  const preference = updateLocalCopy(isUserLevel, id, data);

  addTask(() => {
    setRemoteId(params, id, isUserLevel);
    return preferenceDS.create(params, getStoreOptions(params, isUserLevel)).then((ret) => {
      const remoteIdMap = isUserLevel ? userLevelLocalToRemoteIdMap : localToRemoteIdMap;

      remoteIdMap[id] = ret.Id;
    });
  });

  return Promise.resolve(preference);
};

const set = data => createOrUpdate(data, Level.All);

const setAtUserLevel = data => createOrUpdate(data, Level.User);

const setAtAccountLevel = data => createOrUpdate(data, Level.Account);

const processBulkSetResponse = response => ({
  Action: 'BatchCreate',
  Response: response,
});


const bulkCreate = (params, isUserLevel) => {
  if (!isInitialized) {
    return Promise.resolve(null);
  }

  const options = getStoreOptions(params, isUserLevel);

  options.bulkCreate = true;
  options.action = 'POST';
  options.processResponse = processBulkSetResponse;
  options.overrideResponse = true;

  // Make bulkCreate wait for other tasks to finish
  return addTask(() => preferenceDS.create(params, options).then((result) => {
    if (!result || !result.Response || !_.isArray(result.Response)) {
      return {
        Status: false,
        Response: result.Response,
      };
    }

    let status = false;

    _.forEach(result.Response, (item, index) => {
      if (item.status !== 200 || !item.data) {
        return;
      }

      status = true;
      const data = params.reqs[index];

      data.Data = parseData(data.Data);
      updateLocalCopy(isUserLevel, item.data.Id, data);
    });

    concatLocalRepository();

    return {
      Status: status,
      Response: result.Response,
    };
  }));
};

const delFromStore = (id, isUserLevel) => {
  const params = { Id: id };

  if (isUserLevel === true) {
    localUserLevelRepository = _.reject(localUserLevelRepository, { Id: id });
  } else {
    localAccountLevelRepository = _.reject(localAccountLevelRepository, { Id: id });
  }

  concatLocalRepository();

  addTask(() => {
    setRemoteId(params, id, isUserLevel);
    return preferenceDS.destroy(params.Id, getStoreOptions({}, isUserLevel)).then(() => {
      deleteMappedId(id, isUserLevel);
    });
  });

  return Promise.resolve();
};

const del = id => delFromStore(id, false);

const delAtUserLevel = id => delFromStore(id, true);

export default {
  isInitialized,
  initSync,
  initialize,
  findByPrefix,
  findByName,
  find,
  set,
  setAtUserLevel,
  setAtAccountLevel,
  bulkCreate,
  del,
  setToSession,
  findByNameInSession,
  delFromSession,
  delAtUserLevel,
  findByNameAtUserLevel,
  findByNameAtAccountLevel,
  onRemoteSyncDone,
  onMultipleRemoteSyncDone,
};
