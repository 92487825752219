/* eslint-disable */
import _ from 'underscore';
import { odata } from '@bingads-webui/http-util';
import * as url from '@bingads-webui/url-util';
import { get } from '@bingads-webui-universal/primitive-utilities';
import { odataQuery, odataUri, odataCast } from '@bingads-webui/odata-converter';

function sendBatchRequest(definition, params, options) {
  const reqs = _.map(params, (param) => {
    const id = param.Id;
    const uri = odataUri.convert({ definition, options, id });

    return {
      type: options.action,
      url: uri,
      data: param,
    };
  });

  return odata.batch({ reqs, path: options.path }).then((response) => {
    if (_.isFunction(options.processResponse)) {
      options.processResponse(response);
    }
    return response;
  });
}

function getCommonOptions({
  ajaxOptions = {},
  urlParameters,
  host,
  path,
  headers,
  successCallback,
  state,
  oDataToken,
} = {}) {
  const options = _.defaults({}, ajaxOptions);
  if (_.isObject(urlParameters)) {
    options.urlParameters = urlParameters;
  }
  if (host) {
    options.host = host;
  }
  if (path) {
    options.path = path;
  }
  if (oDataToken) {
    options.oDataToken = oDataToken;
  }
  if (headers) {
    options.headers = headers;
  }
  // <TODO> [lizho] Remove this after related OData APIs are refactored.
  // This callback is to get response header for product offer/product group related OData APIs.
  if (_.isFunction(successCallback)) {
    options.success = successCallback
  }
  // <TODO>
  if (state) {
    options.params = _.clone(state.attributes);
  }
  return options;
}

export class ODataBaseAdapter {
  // All of the methods shown here must return a promise

  // "definition" is a resource defintion that would
  // be returned by DS#defineResource

  // "options" would be the options argument that
  // was passed into the DS method that is calling
  // the adapter method
  constructor(options = {}) {
    this.options = options;
  }

  convertQuery(queryParameters) {
    return odataQuery.convert(queryParameters);
  }

  create(definition, attrs, options) {
    _.extendOwn(options, this.options);
    let data = odataCast.data({ data: attrs, definition, options });
    let urlParameters = options.urlParameters;

    // Below if block is for the calls being made without EDM models
    // This enables making "POST" calls with query parameters without edm.
    if (attrs.queryParams) {
      urlParameters = this.convertQuery(attrs.queryParams);
      data = _.omit(data, 'queryParams');
    }

    // Must resolve the promise with the created item
    // NOTICE: added ajaxOptions here.
    return odata
      .post(odataUri.convert({ definition, options }), _.defaults({ data, urlParameters }, getCommonOptions(options)));
  }

  find(definition, id, options) {
    _.extendOwn(options, this.options);
    let params = {};
    let queryParameters = options.queryParameters;

    if (_.isObject(queryParameters)) {
      params = { data: _.omit(this.convertQuery(queryParameters), '$top', '$count') };
    }

    params.path = options.path;

    // Must resolve the promise with the found item
    return odata.get(odataUri.convert({ definition, options, id, isFinding: true }), _.defaults(params, getCommonOptions(options)));
  }

  findAll(definition, params, options = {}) {
    // Must resolve the promise with the found items
    _.extendOwn(options, this.options);
    const filterOptions = options.filterOptions;

    // params.entitySet is flavored. options.entitySet is only surported for backward support.
    const queryEntitySet = get(params, 'query.entitySet');
    const entitySet = options.edmRootResourceIdentifier && queryEntitySet ?
      options.edmRootResourceIdentifier.identifyEntitySet(queryEntitySet) :
      get(options, 'entitySet');

    let request;

    if (entitySet && options.edm && get(entitySet, 'navigation.source.type') instanceof options.edm.types.ActionType) {
      request = odata
        .post(
          url.queryify(odataUri.convert({ definition, options, isFinding: true, entitySet }), this.convertQuery(_.extend({ filterOptions }, params)), options.ajaxOptions.customParameterizationFunc),
          _.defaults({ data: entitySet.navigation.parameters }, getCommonOptions(options))
        );

      options.cacheResponse = false;
    } else {
      request = odata
        .get(
          odataUri.convert({ definition, options, isFinding: true, entitySet }),
          _.defaults({ data: this.convertQuery(_.extend({ filterOptions }, params)) }, getCommonOptions(options))
        );
    }

    return request.then((res) => {
      if (!res) {
        return null;
      }

      const addPropertyTotalCount = (obj) => Object.defineProperty(obj, 'totalCount', {
        value: res['@odata.count'],
        enumerable: false,
      });

      const addPropertyRaw = (obj) => Object.defineProperty(obj, 'raw', {
        value: res,
        enumerable: false,
      });

      const queryId = _.uniqueId('query-');

      // if OData results is just an array of string, make it an object then assign the object to value.
      if (_.isString(_.first(res.value))) {
        res.value = _.map(res.value, item => ({ id: _.uniqueId('item-'), value: item }));
      }

      // JSData requires ID for every resource
      // Some calls to OData server won't return items with ID, or return items with an empty string as id
      // We add ID here to fit JSData requirement before JSData inject happens
      _.each(res.value, (item, index) => {
        if (!_.has(item, definition.idAttribute) || item[definition.idAttribute] === '') {
          item[definition.idAttribute] = `${queryId}-${index}`;
        }
      });

      const addProperties = _.compose(addPropertyTotalCount, addPropertyRaw);

      if (options.cacheResponse) {
        options.afterInject = _.compose(
          options.afterInject || _.identity,
          (innerOptions, injected) => addProperties(injected)
        );
      } else {
        // if odata response payload is not wrapped in value property,
        // move the contents under res.value
        res.value = _.has(res, 'value') ? res.value : res;
        addProperties(res.value);
      }

      return res.value;
    }, (res) => {
      //in case of error, it returns statusText for caller
      return res.statusText;
    });
  }

  update(definition, id, attrs, options) {
    _.extendOwn(options, this.options);
    const data = odataCast.data({ data: attrs, definition, options });

    return odata
      .patch(
        odataUri.convert({ definition, options, id }),
        // NOTICE: modified to replace app state/config
        _.defaults({ data }, getCommonOptions(options))
      );
  }
  updateAll(definition, attrs, params, options) {
    _.extendOwn(options, this.options);

    // Must resolve the promise with the updated items
    return sendBatchRequest(definition, params.reqs, options);
  }

  destroy(definition, id, options) {
    _.extendOwn(options, this.options);

    // Must return a promise
    return odata
      .delete(odataUri.convert({ definition, options, id }), getCommonOptions(options))
      .then(_.property('value'));
  }

  destroyAll(definition, params, options) {
    _.extendOwn(options, this.options);

    return sendBatchRequest(definition, params.reqs, options);
  }
}
