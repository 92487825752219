import _ from 'underscore';
import * as putil from '@bingads-webui-universal/primitive-utilities';
import { Base } from './base';

/**
 * Define a number validator which check whether input is a number
 * @param {I18n} i18n - provide callback getString to get message,
 * will be called with args 'Validation_Number_Require_Numeric'
 */
class Type extends Base {
  constructor(i18n) {
    super(i18n);

    this.blockInput = false;
    this.message = i18n.getString('Validation_Number_Require_Numeric');
  }

  isValidValue(value) {
    return putil.isNil(value) || _.isNumber(value);
  }
}

/**
 * Define a number validator which check whether input is in a valid size
 * @param {int} integerLength - the length of int
 * @param {int} decimalLength - the length of decimal
 * @param {I18n} i18n - provide callback getString to get message,
 * will be called with args 'Validation_Number_Max_Length'
 */
export class Size extends Base {
  constructor(integerLength, decimalLength, i18n) {
    super(i18n);

    this.blockInput = false;
    this.message = i18n.getString('Validation_Number_Max_Length');
    this.integerLength = integerLength;
    this.decimalLength = decimalLength;
  }

  isValidValue(value) {
    // leave for required validator
    if (putil.isNil(value)) {
      return true;
    }

    // leave for number validator
    if (!_.isNumber(value)) {
      return true;
    }

    let text = value.toString();

    if (value < 0) {
      text = text.substr(1);
    }

    const segments = text.split('.');

    if (this.integerLength && _.size(segments[0]) > this.integerLength) {
      return false;
    }

    if (this.decimalLength === 0 && _.size(segments) > 1) {
      return false;
    }

    if (this.decimalLength && _.size(segments[1]) > this.decimalLength) {
      return false;
    }

    return true;
  }
}

/**
 * Define a nunmber validate which check whether input value in a valid range
 * @param {int|float} min - min value of input
 * @param {int|float} max - max value of input
 * @param {I18n} i18n - provide callback getString to get error message, will be called with args
 * 'Validation_Number_Range_Between', 'Validation_Number_Range_Min' or 'Validation_Number_Range_Max'
 */
export class Range extends Base {
  constructor(min, max, i18n) {
    super(i18n);

    this.min = min;
    this.max = max;

    if (_.isFinite(min) && _.isFinite(max)) {
      this.message = i18n.getString('Validation_Number_Range_Between', {
        min: i18n.formatDecimal(min, { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
        max: i18n.formatDecimal(max, { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
      });
    } else if (_.isFinite(min)) {
      this.message = i18n.getString('Validation_Number_Range_Min', {
        min: i18n.formatDecimal(min, { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
      });
    } else {
      this.message = i18n.getString('Validation_Number_Range_Max', {
        max: i18n.formatDecimal(max, { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
      });
    }
  }

  isValidValue(value) {
    // leave for required validator
    if (putil.isNil(value)) {
      return true;
    }

    // leave for number validator
    if (!_.isNumber(value)) {
      return true;
    }

    let result = true;

    if (_.isFinite(this.min)) {
      result = result && value >= this.min;
    }

    if (_.isFinite(this.max)) {
      result = result && value <= this.max;
    }

    return result;
  }
}

/**
 * Create a number type validator
 * @param {I18n} i18n - provide callback getString to get message
 * @returns {Validator} - number type validator instance
 */
export function type(i18n) {
  return new Type(i18n);
}

/**
 * Create a size validator
 * @param {int} integerLength - the length of int
 * @param {int} decimalLength - the length of decimal
 * @param {I18n} i18n - provide callback getString to get message
 * @returns {Validator} - number size validator instance
 */
export function size(integerLength, decimalLength, i18n) {
  return new Size(integerLength, decimalLength, i18n);
}

/**
 * Create a range validator
 * @param {int|float} min - min value of input
 * @param {int|float} max - max value of input
 * @param {I18n} i18n - provide callback getString to get message
 * @returns {Validator} - number range validator instance
 */
export function range(min, max, i18n) {
  return new Range(min, max, i18n);
}
