import _ from 'underscore';
import { defineConstProperty } from '@bingads-webui/reflection';
import * as urlUtil from '@bingads-webui/url-util';

export function createNavigation() {
  /**
   * @class Navigation
   * @property {ResourceIdentifier} source - The source accessor navigated from
   */
  return class Navigation {
    constructor({
      source,
    }) {
      defineConstProperty(this, 'source', source);
    }

    toJSON() {
      return [...this.source.toJSON(), this.toSelfJSON()];
    }

    toSelfJSON() {
      throw new Error('I\'m abstract');
    }
  };
}

export function createCastNavigation(Superclass = Object, url, edm) {
  /**
   * @class CastNavigation
   * @extends Navigation
   * @property {Type} type - The type casting to
   * @property {String} name - The type name used for casting
   */
  return class CastNavigation extends Superclass {
    constructor({
      source,
      type,
      name = type.name,
    }) {
      super({
        source,
      });

      _.chain(this)
        .defineConstProperty('type', type)
        .defineConstProperty('name', name)
        .value();

      defineConstProperty(this, 'path', url.join(source.path, name));
    }

    toSelfJSON() {
      return {
        type: 'function',
        name: CastNavigation.navigationName,
        parameters: [this.name],
      };
    }

    static get navigationName() {
      return '$cast';
    }

    static defineOn(TypeResID) {
      /**
       * $cast navigation method of the TypeResID
       * @memberof TypeResID#
       * @this TypeResID
       * @param {String} name - The name of the subclass
       * @returns {TypeResID} The ResourceIdentifier to access the subclass object
       */
      function navCastMethod(name) {
        const entityType = edm.types.resolve(name, this.type.namespace);
        const type = TypeResID.prototype.type instanceof edm.types.CollectionType ?
          entityType.collectionType :
          entityType;
        const navigation = new CastNavigation({ source: this, type: entityType, name });

        return new type.ResourceIdentifier({ navigation });
      }

      defineConstProperty(TypeResID.prototype, CastNavigation.navigationName, navCastMethod);
    }
  };
}

export function createWithKeyNavigation(Superclass = Object) {
  /**
   * @class WithKeyNavigation
   * @extends Navigation
   * @property {String|Number} key - The key of the selected entity
   * @property {String|Number} ignoreKeyTypeInPath - By default false. If true, compose path following /<path>/<id>
   */
  return class WithKeyNavigation extends Superclass {
    constructor({
      source,
      key,
      ignoreKeyTypeInPath,
    }) {
      super({
        source,
      });

      defineConstProperty(this, 'key', key);
      defineConstProperty(this, 'path', (() => {
        // In case people pass a decimal string for a integer key
        // We cannot use parseInt directly, it would get a wrong number if the
        // key is beyond Number.MAX_SAFE_INTEGER
        if (_.isString(key)) {
          if (ignoreKeyTypeInPath) {
            return urlUtil.join(source.path, key);
          }
          const keyType = _.chain(source)
            .result('type')
            .result('elementType')
            .result('keyProperty')
            .result('typeName')
            .value();

          if (keyType === 'integer') {
            if (key.match(/^[+-]?(0|[1-9][0-9]*)$/)) {
              return `${source.path}(${key})`;
            }
          }
          if (keyType === 'string') {
            return `${source.path}('${key}')`;
          }
        }

        return `${source.path}(${JSON.stringify(key)})`;
      })());
    }

    toSelfJSON() {
      return {
        type: 'function',
        name: WithKeyNavigation.navigationName,
        parameters: [this.key],
      };
    }

    static get navigationName() {
      return '$withKey';
    }

    static defineOn(TypeResID) {
      /**
       * $withKey navigation method of the TypeResID
       * @memberof TypeResID#
       * @this TypeResID
       * @param {String} key - The key of the element
       * @param {Boolean} ignoreKeyTypeInPath - Decide how to add key to path
       * @returns {ResourceIdentifier}
       * The ResourceIdentifier to access the element with the given key
       */
      function navWithKeyMethod(key, ignoreKeyTypeInPath = false) {
        const navigation = new WithKeyNavigation({ source: this, key, ignoreKeyTypeInPath });

        return new this.type.elementType.ResourceIdentifier({ navigation });
      }

      defineConstProperty(
        TypeResID.prototype,
        WithKeyNavigation.navigationName,
        navWithKeyMethod
      );
    }
  };
}

export function createCallNavigation(Superclass = Object, edm) {
/**
 * @class CallNavigation
 * @extends Navigation
 * @property {Object} parameters - The parameters for the function call
 */
  return class CallNavigation extends Superclass {
    constructor({
      source,
      parameters = {},
    }) {
      super({
        source,
      });
      defineConstProperty(this, 'parameters', parameters);

      const path = source.type instanceof edm.types.ActionType ?
        source.path : `${source.path}(${_.map(parameters, (value, name) => `${name}=${value}`).join(',')})`;

      defineConstProperty(this, 'path', path);
    }

    toSelfJSON() {
      return {
        type: 'function',
        name: CallNavigation.navigationName,
        // for callable, parameters are named
        parameters: [this.parameters],
      };
    }

    static get navigationName() {
      return '$call';
    }

    static defineOn(TypeResID) {
      /**
       * $call navigation method of the TypeResID
       * @memberof TypeResID#
       * @this TypeResID
       * @param {object} parameters - The parameters to call the callable
       * @return {ResourceIdentifier}
       * The ResourceIdentifier accessing the return value of the callable
       */
      function navCallMethod(parameters) {
        const navigation = new CallNavigation({ source: this, parameters });

        return new this.type.returnType.ResourceIdentifier({ navigation });
      }

      defineConstProperty(TypeResID.prototype, CallNavigation.navigationName, navCallMethod);
    }
  };
}


export function createResourceIdentifier() {
/**
 * @class ResourceIdentifier
 * @property {Navigation} navigation
 * - The navigation back tracking the parent ResourceIdentifier
 */
  return class ResourceIdentifier {
    /**
     * Create a ResourceIdentifier
     * @param {Object} [options] - The constructor options
     * @param {Navigation} [options.navigation]
     * - The navigation back tracking the parent ResourceIdentifier
     * @return {Void} Nothing to return
     */
    constructor({
      navigation,
    } = {}) {
      if (navigation) {
        defineConstProperty(this, 'navigation', navigation);
        defineConstProperty(this, 'path', navigation.path);
      } else {
        defineConstProperty(this, 'path', '');
      }
    }

    toJSON() {
      if (this.navigation) {
        return this.navigation.toJSON();
      }

      return [];
    }


    identifyEntitySet(json) {
      // eslint-disable-next-line
      let entitySet = this;

      /* eslint guard-for-in: 0 */
      /* eslint no-restricted-syntax: 0 */
      for (const i in json) {
        const item = json[i];

        if (item.type === 'property') {
          entitySet = entitySet[item.name];
        } else if (item.type === 'function') {
          entitySet = entitySet[item.name](...item.parameters);
        }

        if (!entitySet) {
          return null;
        }
      }

      return entitySet;
    }
  };
}

