import { Base } from './base';

/**
 * Use to create a Customized validator
 * @param {function} method - validate function will be called to validate value
 * @param {function|string} message - a function or string, message of this validator
 * @param {boolean} blockInput - whether block input when validate failed
 * @param {I18n} i18n - i18n to get message, only used for Base class
 */
class Custom extends Base {
  constructor(method, message, blockInput, i18n) {
    super(i18n);

    if (blockInput) {
      this.blockInput = blockInput;
    }

    if (message) {
      this.message = message;
    }

    this.isValid = method;
  }
}

/**
 * Create cusotmize validator instance
 * @param {function} method - validate function will be called to validate value
 * @param {function|string} message - a function or string, message of this validator
 * @param {boolean} blockInput - whether block input when validate failed
 * @param {I18n} i18n - i18n to get message, only used for Base class
 * @returns {Validator} - customized validator instance
 */
export function custom(method, message, blockInput, i18n) {
  return new Custom(method, message, blockInput, i18n);
}
