/**
 * the Reource Identifier plugin for entity-data-model
 * @module component/edm-resource-identifiers/index
 */
import _ from 'underscore';
import {
  defineConstProperty,
  defineProducedPropertyOnClass,
} from '@bingads-webui/reflection';
import * as url from '@bingads-webui/url-util';
import { Registry } from '@bingads-webui/registry';
import { createNavigation, createCastNavigation, createWithKeyNavigation, createCallNavigation, createResourceIdentifier } from '@bingads-webui/edm-shared';

/**
 * @param {EDM} edm - The EDM object to apply this plugin to
 * @return {Void} nothing to return
 */
export function resIdsPlugin(edm) {
  if (edm.resourceIdentifiers) {
    return;
  }

  /**
   * @name edm
   * @property {Registry} resourceIdentifiers - A registry of all resource-identifiers
   * @property {Class} resourceIdentifiers.Navigation - The base type of all navigations
   * @property {Class} resourceIdentifiers.PropertyNavigation - The navigation following a property
   * @property {Class} resourceIdentifiers.CastNavigation - The navigation following a type casting
   * @property {Class} resourceIdentifiers.WithKeyNavigation
   * - The navigation of selecting a single instance from an entity set
   * @property {Class} resourceIdentifiers.CallNavigation - The navigation of calling a callable
   * @property {Class} resourceIdentifiers.ResourceIdentifier
   * - The base type of all resource-identifiers
   */
  defineConstProperty(edm, 'resourceIdentifiers', (() => {
    const resourceIdentifiers = new Registry();
    const Navigation = createNavigation();

    /**
     * @class PropertyNavigation
     * @extends Navigation
     * @property {Property} property - The property navigated with
     */
    class PropertyNavigation extends Navigation {
      constructor({
        source,
        property,
        name,
      }) {
        super({
          source,
        });
        defineConstProperty(this, 'property', property);
        defineConstProperty(this, 'name', name);
        defineConstProperty(this, 'path', url.join(source.path, name));
      }

      toSelfJSON() {
        return {
          type: 'property',
          name: this.name,
        };
      }

      static defineOn(TypeResID, navigationProperties) {
        _.each(navigationProperties, (property, name) => {
          /**
           * Navigation property factory for the defined property
           * @memberof TypeResID#
           * @this TypeResID
           * @returns {ResourceIdentifier} The ResourceIdentifier to access the property
           */
          function navPropFactory() {
            return new property.type.ResourceIdentifier({
              navigation: new PropertyNavigation({ source: this, property, name }),
            });
          }

          defineProducedPropertyOnClass(TypeResID, name, navPropFactory);
        });
      }
    }

    class CallableNavigation extends Navigation {
      constructor({
        source,
        name,
      }) {
        super({
          source,
        });
        defineConstProperty(this, 'name', name);
        defineConstProperty(this, 'path', url.join(source.path, name));
      }

      static defineOn(TypeResID, callable) {
        function defineCallableOn(resID, callableTypes) {
          _.each(callableTypes, (type) => {
            /**
             * factory for the callables
             * @memberof resID#
             * @this resID
             * @returns {ResourceIdentifier} The ResourceIdentifier to access the property
             */
            function factory() {
              return new type.ResourceIdentifier({
                navigation: new CallableNavigation({ source: this, name: type.callableName }),
              });
            }
            defineProducedPropertyOnClass(resID, type.callableName, factory);
          });
        }
        defineCallableOn(TypeResID, callable.actions);
        defineCallableOn(TypeResID, callable.functions);
      }
    }

    const CastNavigation = createCastNavigation(Navigation, url, edm);
    const WithKeyNavigation = createWithKeyNavigation(Navigation);
    const CallNavigation = createCallNavigation(Navigation, edm);
    const ResourceIdentifier = createResourceIdentifier();

    /**
     * @class CollectionResourceIdentifier
     * @property {Navigation} navigation
     * - The navigation back tracking the parent ResourceIdentifier
     */
    class CollectionResourceIdentifier extends ResourceIdentifier {
      /**
       * Create a ResourceIdentifier
       * @param {Object} [options] - The constructor options
       * @param {Navigation} [options.navigation]
       * - The navigation back tracking the parent ResourceIdentifier
       * @return {Void} Nothing to return
       */
      constructor({
        navigation,
      } = {}) {
        super({ navigation });
      }
    }

    /**
     * define ResourceIdentifier type for the given type
     * @param {Type} type - An instance of one of the meta types
     * @return {Class} the ResourceIdentifier class
     */
    function resourceIdentifierForType(type) {
      // duck type, if the type has a "baseType", use its ResourceIdentifier as the super class
      const DefaultBase = type instanceof edm.types.CollectionType ?
        CollectionResourceIdentifier :
        ResourceIdentifier;
      const Base = type.baseType ? type.baseType.ResourceIdentifier : DefaultBase;

      const AccessorType = class extends Base {
      };

      /**
       * @name resId
       * @type ResourceIdentifier
       * @property {Type} type - The type associated with the ResourceIdentifier
       */
      defineConstProperty(AccessorType.prototype, 'type', type);

      resourceIdentifiers.register(AccessorType, type.name);

      return AccessorType;
    }

    // For each of the meta types defined by the type mixin, define the
    // "ResourceIdentifier" property
    _.each({

      // the root class 'Type' don't have an ResourceIdentifier property
      // Type: { factory() { } },

      PrimitiveType: {

        /**
         * PrimitiveType ResourceIdentifier type factory
         * @memberof PrimitiveType#
         * @this PrimitiveType
         * @returns {Class} The ResourceIdentifier type of the PrimitiveType
         */
        factory() {
          const PrimitiveTypeResID = resourceIdentifierForType(this);

          return PrimitiveTypeResID;
        },
      },

      ObjectType: {

        /**
         * ObjectType ResourceIdentifier type factory
         * @memberof ObjectType#
         * @this ObjectType
         * @returns {Class} The ResourceIdentifier type of the ObjectType
         */
        factory() {
          const ObjectTypeResID = resourceIdentifierForType(this);

          CastNavigation.defineOn(ObjectTypeResID);
          PropertyNavigation.defineOn(ObjectTypeResID, this.navigationProperties);
          if (this.callable) {
            CallableNavigation.defineOn(ObjectTypeResID, this.callable);
          }
          return ObjectTypeResID;
        },
      },

      // fallback to ObjectType.prototype.ResourceIdentifier
      // EntityType: { factory () { } },

      // fallback to ObjectType.prototype.ResourceIdentifier
      // ComplexType: { factory () { } },

      CollectionType: {

        /**
         * CollectionType ResourceIdentifier type factory
         * @memberof CollectionType#
         * @this CollectionType
         * @returns {Class} the ResourceIdentifier type of the CollectionType
         */
        factory() {
          const CollectionTypeResID = resourceIdentifierForType(this);

          CastNavigation.defineOn(CollectionTypeResID);
          // Only the entity sets can be navigated with keys
          if (this.elementType instanceof edm.types.EntityType) {
            WithKeyNavigation.defineOn(CollectionTypeResID);
          }
          PropertyNavigation.defineOn(CollectionTypeResID, this.navigationProperties);
          if (this.callable) {
            CallableNavigation.defineOn(CollectionTypeResID, this.callable);
          }

          return CollectionTypeResID;
        },
      },

      CallableType: {

        /**
         * CallableType ResourceIdentifier type factory
         * @memberof CallableType#
         * @this CallableType
         * @returns {Class} The ResourceIdentifier type of the CallableType
         */
        factory() {
          const CallableTypeResID = resourceIdentifierForType(this);

          CallNavigation.defineOn(CallableTypeResID);

          return CallableTypeResID;
        },
      },

    }, (def, typeName) => {
      /**
       * @name type
       * @type Type
       * @property {Class} ResourceIdentifier - The ResourceIdentifier type of the type
       */
      defineProducedPropertyOnClass(edm.types[typeName], 'ResourceIdentifier', def.factory);
    });

    _.chain(resourceIdentifiers)
      .defineConstProperty('ResourceIdentifier', ResourceIdentifier)
      .defineConstProperty('CollectionResourceIdentifier', CollectionResourceIdentifier)
      .defineConstProperty('Navigation', Navigation)
      .defineConstProperty('PropertyNavigation', PropertyNavigation)
      .defineConstProperty('CastNavigation', CastNavigation)
      .defineConstProperty('WithKeyNavigation', WithKeyNavigation)
      .defineConstProperty('CallNavigation', CallNavigation)
      .defineConstProperty('CallableNavigation', CallableNavigation)
      .value();

    return resourceIdentifiers;
  })());
}
