import * as odata from './src/channel/odata';
import * as http from './src/channel/core';
import * as batchUtil from './src/util/multipart';

const httpWithDelete = {
  signature: http.signature,
  ajax: http.ajax,
  defaults: http.defaults,
  xhr: http.xhr,
  get: http.get,
  post: http.post,
  put: http.put,
  patch: http.patch,
  delete: http.$delete,
};

const odataWithDelete = {
  defaults: odata.defaults,
  get: odata.get,
  post: odata.post,
  put: odata.put,
  patch: odata.patch,
  delete: odata.$delete,
  ajax: odata.ajax,
  batch: odata.batch,
  upload: odata.upload,
};

export {
  httpWithDelete as http,
  odataWithDelete as odata,
  batchUtil,
};
