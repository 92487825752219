import _ from 'underscore';
import Cldr from 'cldrjs';
import core from '@bingads-webui-universal/i18n-cldr-data-modern/core.json';

Cldr.load(core);

export function getCldrLocale(locale) {
  const cldr = new Cldr(locale);
  const {
    language,
    region,
    script,
  } = cldr.attributes;

  const output = _.find([
    `${language}-${script}-${region}`,
    `${language}-${region}`,
    `${language}-${script}`,
    language,
  ], l => _.contains(core.availableLocales.modern, l));

  return output;
}
