import JSData from '@bingads-webui/js-data-patch';
import { JsDataRestAdapter } from './jsDataRestAdapter';

/**
 * This class is a extension of JSData DS(data store)
 * @param {object} restfulService - (required) a wrapper of ajax request
 */
export class JsDataRestStore extends JSData.DS {
  constructor({
    restfulService,
  }) {
    super().registerAdapter('rest', new JsDataRestAdapter({ restfulService }), { default: true });
  }

  /**
   * Define resource using JSData data store
   * @param {string} resourceName - (required) the name of resource
   * @param {object} uriPath - (required) the default uri path for each resource
   * @param {string} primaryKey - (optional) the primary key for the resource, default is 'id'
   *
   * @returns {object} the JsData resource
   */
  defineRestResource(resourceName, { uriPath, primaryKey = 'id' }) {
    if (this.definitions && this.definitions[resourceName]) {
      return this.definitions[resourceName];
    }
    return this.defineResource({
      name: resourceName,
      meta: { uriPath },
      idAttribute: primaryKey,
    });
  }
}
