import _ from 'underscore';
import URI from 'urijs';

/**
 * Generate uri for restful ajax request
 * @param {object} definition - resource definition
 * @param {object} properties - query options collection
 * @param {object} options - options collection to help generate URL
 *
 * @returns {string} the uri for restful ajax request
 */
const generateUri = function generate(definition, properties, options) {
  let query = '';

  if (!_.isEmpty(properties)) {
    const queryParam = {};

    _.each(properties, (value, key) => {
      switch (key) {
        case 'orderBy':
          if (_.isString(value)) {
            queryParam.$orderby = `${value} asc`;
          } else if (_.isArray(value) && _.isArray(value[0]) && value[0].length > 1) {
            queryParam.$orderby = `${value[0][0]} ${value[0][1].toLowerCase()}`;
          } else if (_.isObject(value)) {
            const name = _.keys(value)[0];
            const direction = value[name] > 0 ? 'asc' : 'desc';

            queryParam.$orderby = `${name} ${direction}`;
          }
          break;
        // Todo: may need a better design of this part,using 'query' here is for compatibility.
        case 'query':
          _.extend(queryParam, value);
          break;
        case 'limit':
          if (!_.isNumber(value) || _.isNaN(value)) {
            throw new TypeError('limit must be a number and not NaN');
          } else if (value !== Number.MAX_VALUE) {
            queryParam.$top = value;
          }
          break;
        case 'offset':
          queryParam.$skip = value;
          break;
        default:
          queryParam[key] = value;
          break;
      }
    });
    query = `?${URI.buildQuery(queryParam, false, false)}`;
  }

  let path = definition.meta.uriPath;
  if (options && !_.isEmpty(options.pathExtension)) {
    _.each(options.pathExtension, (value, key) => {
      path = `${path}/${key}/${value}`;
    });
  }
  return `${path}${query}`;
};

const checkAndAddPrimaryKey = (primaryKey, items) => {
  const queryId = _.uniqueId('query-');
  // JSData requires ID for every resource, some calls won't return items with ID
  // We add ID here to fit JSData requirement before JSData inject happens
  _.each(items, (item, index) => {
    if (!_.has(item, primaryKey)) {
      item[primaryKey] = `${queryId}-${index}`; // eslint-disable-line no-param-reassign
    }
  });
  return items;
};

/**
 * This class is a extension of JSData DS(data store)
 * @param {object} restfulService - (required) a wrapper of ajax request
 */
export class JsDataRestAdapter {
  constructor({
    restfulService,
  }) {
    this.restfulService = restfulService;
  }

  /**
   * implement of JSData API create
   * @param {object} definition - is a resource definition that would
   *                                         be returned by DS#defineResource
   * @param {object} attrs - properties with which to create the item
   * @param {object} [options] - would be options argument that was passed into
   *                             function DS method that is calling the adapter method
   *
   * @returns {promise} will return a promise
   */
  create(definition, attrs, options) {
    // Must resolve the promise with the created item
    return this.restfulService.post(generateUri(definition, {}, options), attrs, options);
  }

  /**
   * implement of JSData API find
   * @param {object} definition - is a resource definition that would
   *                                         be returned by DS#defineResource
   * @param {string|number} id - primary key of the item to retrieve
   * @param {object} [options] - would be options argument that was passed into
   *                             function DS method that is calling the adapter method
   *
   * @returns {promise} will return a promise
   */
  find(definition, id, options) {
    // Must return a promise that resolves with the found item
    return this.restfulService.get(generateUri(definition, {
      [definition.idAttribute]: id,
    }, options), options);
  }

  /**
   * implement of JSData API findAll
   * @param {object} definition - is a resource definition that would
   *                              be returned by DS#defineResource
   * @param {object} params - query parameters for selecting items from the collection
   * @param {object} [options] - would be options argument that was passed into
   *                             function DS method that is calling the adapter method
   *
   * @returns {Promise.<Object[]>} Promise of item list. ID will be added to item if it's missing
   *                               total and other properties will be added as well.
   */
  findAll(definition, params, options) {
    // Must return a promise that resolves with the found items
    return this.restfulService.get(generateUri(definition, params, options), options)
      .then((res) => {
        if (options && _.isFunction(options.preProcess)) {
          // eslint-disable-next-line no-param-reassign
          res = options.preProcess(res);
        }

        const addPropertyTotalCount = obj => Object.defineProperty(obj, 'totalCount', {
          value: res.total,
          enumerable: false,
        });

        const addPropertyRaw = obj => Object.defineProperty(obj, 'raw', {
          value: res,
          enumerable: false,
        });

        const addProperties = _.compose(addPropertyTotalCount, addPropertyRaw);

        // To handle different format response, some calls will return
        // {total: a number, value: a list of items},
        // For these cases we need change the value.
        const items = checkAndAddPrimaryKey(definition.idAttribute, _.has(res, 'value') ? res.value : res);

        if (res.value && options.cacheResponse) {
          options.afterInject = _.compose( // eslint-disable-line no-param-reassign
            options.afterInject || _.identity,
            (innerOptions, injected) => addProperties(injected)
          );
        } else {
          addProperties(items);
        }
        return items;
      });
  }

  /**
   * implement of JSData API update
   * @param {object} definition - is a resource definition that would
   *                              be returned by DS#defineResource
   * @param {string|number} id - primary key of the item to update
   * @param {object} attrs - properties with which to update the item.
   * @param {object} [options] - would be options argument that was passed into
   *                           function DS method that is calling the adapter method
   *
   * @returns {promise} will return a promise
   */
  update(definition, id, attrs, options) {
    // Must return a promise that resolves with the update items
    return this.restfulService.patch(generateUri(definition, { id }, options), attrs, options);
  }

  /**
   * implement of JSData API updateAll
   * @param {object} definition - is a resource definition that would
   *                              be returned by DS#defineResource
   * @param {object} attrs - the attributes which to update the resource.
   * @param {object} [params] - query parameters for selecting which items to update. Default: {}.
   * @param {object} [options] - would be options argument that was passed into
   *                             function DS method that is calling the adapter method
   *
   * @returns {Promise.<Object[]>} Promise of item list. ID will be added to item if it's missing
   */
  updateAll(definition, attrs, params, options) {
    return this.restfulService.patch(generateUri(definition, params, options), attrs, options)
      .then(res => checkAndAddPrimaryKey(definition.idAttribute, _.has(res, 'value') ? res.value : res));
  }

  /**
   * implement of JSData API destroy
   * @param {object} definition - is a resource definition that would
   *                              be returned by DS#defineResource
   * @param {string|number} id - primary key of the item to destroy
   * @param {object} [options] - would be options argument that was passed into
   *                             function DS method that is calling the adapter method
   *
   * @returns {promise} will return a promise
   */
  destroy(definition, id, options) {
    // Must return a promise
    return this.restfulService.delete(generateUri(definition, { id }, options), options);
  }

  /**
   * implement of JSData API destroyAll
   * @param {object} definition - is a resource definition that would
   *                              be returned by DS#defineResource
   * @param {object} params - query parameters for selecting which items to destroy
   * @param {object} [options] - would be options argument that was passed into
   *                             function DS method that is calling the adapter method
   *
   * @returns {promise} will return a promise
   */
  destroyAll(definition, params, options) {
    // Must return a promise
    return this.restfulService.delete(generateUri(definition, params, options), options);
  }
}
