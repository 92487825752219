import _ from 'underscore';

const operatorMapping = {
  number: ['eq', 'neq', 'gt', 'gte', 'lt', 'lte'],
  integer: ['eq', 'neq', 'gt', 'gte', 'lt', 'lte'],
  array: ['eq', 'neq', 'gt', 'gte', 'lt', 'lte'],
  string: ['contains', 'doesnotcontain', 'startswith', 'endswith', 'eq', 'neq'],
  enum: ['in'],
  boolean: ['eq'],
  datetime: ['eq', 'neq', 'gt', 'gte', 'lt', 'lte'],
  id: ['in'],
};

function getSelfFilterableProps(schema) {
  return _.chain(schema.properties)
    .pairs()
    .filter(pair => pair[1] && pair[1].filterable === true)
    .object()
    .value();
}

function getDeepFilterableProps(schema) {
  return _.chain(schema.properties)
    .pairs()
    .filter(pair => pair[1].type && pair[1].type.toLowerCase() === 'object' && pair[1].schema && pair[1].filterable)
    .map((pair) => {
      const parentKey = pair[0];

      return _.chain(pair[1].schema.properties)
        // pick only filterable properties
        .pick(pair[1].filterable)
        .pairs()
        .map((childPair) => {
          // set alias on property object
          const prop = _.extend({}, childPair[1], {
            alias: pair[1].alias && pair[1].alias[childPair[0]],
          });
          // return key-value pair with key = parent/child
          return [parentKey.concat('.', childPair[0]), prop];
        })
        .value();
    })
    .flatten(true)
    .object()
    .value();
}

function buildAliasToProp() {
  return _.chain(this.filterProps())
    .pairs()
    .map(pair => _.map(
      _.pairs(pair[1].alias || {}),
      childPair => [childPair[0].concat('.', childPair[1]), pair[0]]
    ))
    .flatten(true)
    .object()
    .value();
}

function buildPropToAlias() {
  return _.chain(this.aliasToProp())
    .pairs()
    .map((pair) => {
      const dot = pair[0].indexOf('.');
      const aliasType = pair[0].substring(0, dot);
      const alias = pair[0].substring(dot + 1);
      return [aliasType.concat('.', pair[1]), alias];
    })
    .object()
    .value();
}

function convertValue(srcPropType, targetPropType, predValue) {
  const srcType = srcPropType.toLowerCase();
  const targetType = targetPropType.toLowerCase();
  // only convert some types
  if (srcType === 'number' && targetType === 'integer') {
    return Math.round(predValue);
  }
  return predValue;
}

const conversionFunctionFactory = ({
  sourcePropertyType, targetPropName, targetProp,
}) => {
  const conversionFunc = (mongoPredicate) => {
    const exprObj = _.values(mongoPredicate)[0];
    const predOperator = _.keys(exprObj)[0];
    const predValue = _.values(exprObj)[0];

    // All we need here is the type for src
    const convertedValue = convertValue(sourcePropertyType, targetProp.type, predValue);

    return {
      [targetPropName]: {
        [predOperator]: convertedValue,
      },
    };
  };
  return conversionFunc;
};

const legacyConversionFunctionFactory = ({
  sourcePropertyType, targetPropName, targetProp,
}) => {
  const conversionFunc = (legacyPredicate) => {
    const predValue = legacyPredicate.Values[0];

    const convertedValue = convertValue(sourcePropertyType, targetProp.type, predValue);

    /* eslint-disable no-param-reassign */
    legacyPredicate.SelectedColumn = targetPropName;
    legacyPredicate.Values = [convertedValue];
    /* eslint-enable no-param-reassign */
  };
  return conversionFunc;
};

function buildPropToConversions(isForLegacyFilter, conversionFuncFactory) {
  return _.chain(this.entitySchema.properties)
    .pairs()
    .map(([targetPropName, targetProp]) => {
      // Only one property conversion are supported for now.
      if (targetProp.alias && targetProp.alias.propertyConversion) {
        // `conversionSrc` must have `sourcePropertyName` and `sourcePropertyType`.
        // `sourcePropertyName` is long name with '' in b/w parentName and property name.
        // `sourcePropertyLegacyAlias` is the optional legacy alias of the source property.
        const { sourcePropertyType, sourcePropertyName, sourcePropertyLegacyAlias } =
          targetProp.alias.propertyConversion;

        if (!sourcePropertyName) {
          throw new Error('Must provide a sourcePropertyName');
        }
        if (!sourcePropertyType) {
          throw new Error('Must provide a sourcePropertyType');
        }

        const conversionFunc = conversionFuncFactory({
          sourcePropertyType, targetPropName, targetProp,
        });
        const finalSourcePropertyName =
          (isForLegacyFilter && sourcePropertyLegacyAlias) || sourcePropertyName;

        return [finalSourcePropertyName, conversionFunc];
      }
      return null;
    })
    .compact()
    .object()
    .value();
}

/**
 * FilterSchema is built upon entity schema.
 * It's used to get filterable properties, get alias using alias type & property key and vice versa.
 */
export class FilterSchema {
  /**
   * Constructs a new instance of FilterSchema over the passed in entitySchema.
   * @param {object} entitySchema - the original entity schema object
   */
  constructor(entitySchema) {
    this.entitySchema = entitySchema;
    this.entityName = this.entitySchema.name;

    this.filterProps = () => _.extend(
      getSelfFilterableProps(this.entitySchema),
      getDeepFilterableProps(this.entitySchema)
    );
    this.aliasToProp = _.once(buildAliasToProp.bind(this));
    this.propToAlias = _.once(buildPropToAlias.bind(this));
    this.propToConversions =
      _.once(buildPropToConversions.bind(this, false, conversionFunctionFactory));
    this.propToLegacyConversions =
      _.once(buildPropToConversions.bind(this, true, legacyConversionFunctionFactory));
  }

  /**
   * Get entity schema's name.
   * @return {string} - the entity schema's name.
   */
  getEntityName() {
    return this.entityName;
  }

  /**
   * Get keys of filterable properties.
   * @return {array} - array of filterable properties' keys.
   */
  getPropertyKeys() {
    return _.keys(this.filterProps());
  }

  /**
   * Get filterable property using property key with formatted attributes.
   * @param {string} propertyKey - the property's key
   * @return {object} - filterable property of given key, null if doesn't exist.
   */
  getFilterProperty(propertyKey) {
    const property = this.filterProps()[propertyKey];
    if (!property) {
      return null;
    }

    const type = property.type.toLowerCase();
    let operators;

    if (!_.isEmpty(property.operators)) {
      operators = property.operators; // eslint-disable-line prefer-destructuring
    } else if (_.isEmpty(property.enum)) {
      operators = operatorMapping[type];
    } else {
      operators = operatorMapping.enum;
    }

    const result = {
      type,
      operators,
      alias: property.alias,
      name: property.name,
      description: property.description,
      isPercentValue: property.isPercentValue,
      skipLocalization: property.skipLocalization,
      isDeletedProperty: property.isDeletedProperty,
      isInvalidProperty: property.isInvalidProperty,
      title: property.title,
      localized: property.localized,
      nullable: property.nullable,
      maxLength: property.maxLength,
      minimum: property.minimum,
      maximum: property.maximum,
      pattern: property.pattern,
      customEditor: property.customEditor,
      localize: property.localize,
      enumType: property.enumType,
    };

    if (property.enum) {
      result.enum = property.enum;
    }
    if (property.chooseType) {
      result.chooseType = property.chooseType;
    }
    if (property.customParameters) {
      result.customParameters = property.customParameters;
    }
    if (property.groupedItems) {
      result.groupedItems = property.groupedItems;
    }
    if (property.hasTime) {
      result.hasTime = property.hasTime;
    }

    if (property.operatorDisplayNameMap) {
      // for customize filter operator text display
      result.operatorDisplayNameMap = property.operatorDisplayNameMap;
    }
    if (property.addtionalParams && _.isObject(property.addtionalParams)) {
      // to avoid future trouble, for non-shared params, put it here
      _.extendOwn(result, property.addtionalParams);
    }
    return result;
  }

  /**
   * Get alias using property key and alias type.
   * @param {string} propertyKey - property key.
   * @param {string} aliasType - alias type.
   * @return {string} - alias name of given key and alias type
   *                    return propertyKey if alias doesn't exist.
   */
  toAlias(propertyKey, aliasType) {
    let alias = null;
    if (propertyKey && aliasType) {
      alias = this.propToAlias()[aliasType.concat('.', propertyKey)];
    }
    return alias || propertyKey;
  }

  /**
   * Get original property key using alias and alias type.
   * @param {string} alias - alias string.
   * @param {string} aliasType - type of alias.
   * @return {string} - the property key of given alias and alias type
   *                    return alias if propertyKey doesn't exist.
   */
  fromAlias(alias, aliasType) {
    let propertyKey = null;
    if (alias && aliasType) {
      propertyKey = this.aliasToProp()[aliasType.concat('.', alias)];
    }
    return propertyKey || alias;
  }

  getApplicableConversions(propName) {
    const allConversions = this.propToConversions();
    return _.isUndefined(allConversions[propName]) ? _.identity : allConversions[propName];
  }

  getApplicableLegacyConversions(propName) {
    const allConversions = this.propToLegacyConversions();
    return _.isUndefined(allConversions[propName]) ? _.identity : allConversions[propName];
  }

  applyConversions(mongoPredicate) {
    const propName = _.keys(mongoPredicate)[0];
    return this.getApplicableConversions(propName)(mongoPredicate);
  }

  applyLegacyFilterConversions(legacyFilterExpressions) {
    _.each(legacyFilterExpressions, (legacyExpression) => {
      const propName = legacyExpression.SelectedColumn;
      this.getApplicableLegacyConversions(propName)(legacyExpression);
    });
  }
}
