export const AccountCustomerLinkType = Object.freeze({
  Unknown: 0,
  DirectParent: 1,
  AgencyCustomer: 2,
  ParentInHierachy: 3, // * Account's parent in a Hierachy with Target Customer
  AgencyInHierachy: 4, // * Account's Agency in a Hierachy with Target Customer
});

export const AccountLinkPermissionType = Object.freeze({
  Unknown: 0,
  Standard: 1,
  AccountCampaignManagement: 2,
  Billing: 3,
  ReadOnly: 4,
});

export const MultiAgencyHeaderHide = Object.freeze({
  [AccountLinkPermissionType.Billing]: {
    import: true,
    myWebsite: true,
    mmc: true,
    assetLibrary: true,
  },
});
