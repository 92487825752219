import _ from 'underscore';
import { odata } from '@bingads-webui/http-util';

export class ImageODataService {
  constructor({
    cid,
    aid,
    host,
    path,
    token,
    controller,
  }) {
    this.cid = cid;
    this.aid = aid;
    this.host = host;
    this.path = path;
    this.token = token;
    this.controller = controller || 'Images';
  }

  /**
   * Get the images using the OData
   *
   * @param {Object} options - options for getting
   * @param {Number} options.top - top
   * @param {Number} options.skip - skip
   * @param {Object} options.customQueries - key value pair for the additional queries.
   *
   * @returns {Promise} - Promise of getting the images
   */
  getImages(options) {
    const defaultOptions = {
      top: 12,
      skip: 0,
      customQueries: {},
    };

    const optionsWithDefault = _.defaults(options, defaultOptions);

    const url = `/Customers(${this.cid})/Accounts(${this.aid})/${this.controller}`;

    const query = _.extend({
      $top: optionsWithDefault.top,
      $skip: optionsWithDefault.skip,
    }, optionsWithDefault.customQueries);

    return odata.get(url, {
      data: query,
      host: this.host,
      path: this.path,
      oDataToken: this.token,
    });
  }

  /**
   * Post image with the passed in data
   *
   * @param {Object} body - object containing the upload data
   *
   * @returns {Promise} - Promise reponse form the OData call
   */
  postImage(body) {
    const url = `/Customers(${this.cid})/Accounts(${this.aid})/${this.controller}`;

    return odata.post(url, {
      data: body,
      host: this.host,
      path: this.path,
      oDataToken: this.token,
    });
  }

  /**
   * Patch image with the passed in data
   *
   * @param {Number} id - id of the image to patch
   * @param {Object} body - object containing the patch data
   *
   * @returns {Promise} - Promise reponse form the OData call
   */
  patchImage(id, body) {
    const url = `/Customers(${this.cid})/Accounts(${this.aid})/${this.controller}(${id})`;

    return odata.patch(url, {
      data: body,
      host: this.host,
      path: this.path,
      oDataToken: this.token,
    });
  }

  /**
   * Delete image by the id passed in
   *
   * @param {Number} id - Id of the image to be deleted.
   *
   * @returns {Promise} - Promise response from the OData call.
   */
  deleteImage(id) {
    const url = `/Customers(${this.cid})/Accounts(${this.aid})/${this.controller}(${id})`;

    return odata.delete(url, {
      host: this.host,
      path: this.path,
      oDataToken: this.token,
    });
  }

  /**
   * Get image by the id passed in
   *
   * @param {Number} id - Id of the iamge to be requested.
   *
   * @returns {Promise} - Promise response from the OData call.
   */
  getImage(id) {
    const url = `/Customers(${this.cid})/Accounts(${this.aid})/${this.controller}(${id})`;

    return odata.get(url, {
      host: this.host,
      path: this.path,
      oDataToken: this.token,
    });
  }
}
