import JSData from '@bingads-webui/js-data-patch';
import { ODataBaseAdapter } from '@bingads-webui/js-data-odata-base-adapter';

const dataStore = new JSData.DS({ cacheResponse: false });

dataStore.registerAdapter(
  'odata',
  new ODataBaseAdapter({}),
  { default: true }
);

const preferenceDS = dataStore.defineResource({
  name: 'Preference',
  idAttribute: 'Id', // Customized on the idAttribute
});

export {
  preferenceDS,
};
