import _ from 'underscore';
import { get, set } from '@bingads-webui-universal/primitive-utilities';
import stringify from 'json-stable-stringify';
import { TimeAwareCache, LRUReplacementStrategy } from '@bingads-webui-universal/time-aware-cache';
import { CACHE_SIZE, DEFAULT_TTL } from './constants';

function deletePropertyPath(obj, path) {
  let o = obj;
  const { length } = path;
  for (let i = 0; i < length - 1; i += 1) {
    o = o[path[i]];

    if (typeof o === 'undefined') {
      return;
    }
  }
  delete o[path[length - 1]];
}

export class ObservableCache {
  observedCache = {};
  noObservers = new TimeAwareCache({
    ttl: DEFAULT_TTL,
    strategy: new LRUReplacementStrategy({ limit: CACHE_SIZE }),
  });

  set(path, item) {
    if (item.hasObservers()) {
      set(this.observedCache, path, item);
    } else {
      this.noObservers.set(stringify(path), item);
    }
  }

  get(path) {
    return get(this.observedCache, path) || this.noObservers.get(stringify(path));
  }

  observerAdded(item, path) {
    if (item.observers.size === 1) {
      this.noObservers.del(stringify(path));
      set(this.observedCache, path, item);
    }
  }

  observerReduced(item, path) {
    if (item.observers.size === 0) {
      deletePropertyPath(this.observedCache, path);
      this.noObservers.set(stringify(path), item);
    }
  }

  invalidateObservedCache(typeName, skipEntityCallPaths = null) {
    const clearCacheKeys = [];
    _.map(this.observedCache[typeName], (cacheItems, subKey) => {
      const skipCall = skipEntityCallPaths && _.find(skipEntityCallPaths, path => subKey.includes(path) || `${typeName}s(${subKey})`.includes(path));
      if (skipCall) {
        clearCacheKeys.push(subKey);
      } else {
        _.each(cacheItems, (cacheItem) => {
          cacheItem.invalidate();
        });
      }
    });
    clearCacheKeys.forEach((key) => {
      delete this.observedCache[typeName][key];
    });
  }

  invalidateObservedCacheById(typeName, id, stringifiedOptions, skipEntityCallPaths = null) {
    const skipCall = skipEntityCallPaths && _.find(skipEntityCallPaths, path => `${typeName}s(${id})`.includes(path));
    if (skipCall) {
      delete this.observedCache[typeName][id];
    } else {
      _.each(this.observedCache[typeName][id], (cacheItem, options) => {
        if (options !== stringifiedOptions) {
          cacheItem.invalidate();
        }
      });
    }
  }

  invalidateNoObserverCache(key) {
    const cache = this.noObservers.get(key);

    if (cache) {
      cache.invalidate();
    }
  }
}
