/* eslint-disable */
import _ from 'underscore';

// this is for backward compatibility. legacy code put schema to property named schema
function getSchemaOfSubAttr(schema, attrName) {
  if (schema.properties[attrName].type === 'array') {
    return schema.properties[attrName].items.schema || schema.properties[attrName].items;
  }

  return schema.properties[attrName].schema || schema.properties[attrName];
}

// this is for backward compatibility. new schema put name in $$ODataExtension
function getSchemaName(schema) {
  return schema.name || schema.$$ODataExtension.Name;
}

export function serialize(schema, attrs) {
  var rows = getCSVRows(schema, attrs);

  var formatRow = {
    Type: 'Format Version',
    Name: '6'
  };

  rows.splice(0, 0, formatRow);

  return generateCsvRows(rows);
}

function getCSVRows(rootSchema, attributesList, clientIdPrefix) {

  var csvRows = [];

  //ensuring that the input list is an array (first level entity is always a solo object)
  if (!_.isArray(attributesList)) {
    attributesList = [attributesList];
  }

  var traverses = [];
  var clientIds = [];
  _.each(attributesList, function(attributes, index) {
    if((!_.isString(attributes['Client Id']) && !_.isNumber(attributes['Client Id'])) || _.contains(clientIds, attributes['Client Id'])) {
      attributes['Client Id'] = (clientIdPrefix ? (clientIdPrefix + '-'): '') + index.toString();
    }

    var traverseResult = traverseFields(attributes, rootSchema);
    traverses.push(traverseResult);
    clientIds.push(attributes['Client Id']);
  });

   var nextRootResults = [];
  _.each(traverses, function(rowResult, rowIndex) {
    var attributes = rowResult.attributes,
        currentRow = rowResult.currentRow,
        nextRoots = rowResult.nextRoots,
        nextRootSchemas = rowResult.nextRootSchemas;
    if (rootSchema.properties['bulk-upload-type']) {
      currentRow['Type'] = rootSchema.properties['bulk-upload-type'].value;
    }
    csvRows.push(currentRow);

    _.each(nextRoots, function(nextRoot, index) {
      var csvRows = getCSVRows(nextRootSchemas[index], attributes[nextRoot], clientIds[rowIndex] + '-' + index.toString());
      nextRootResults.push(csvRows);
    });
  });


  _.each(_.flatten(nextRootResults, true), function(row) {
    csvRows.push(row);
  });

  return csvRows;
}

function getCsvHeaderName(attr, schema) {
  return !_.isUndefined(schema.properties[attr]['bulk-upload-header']) ? schema.properties[attr]['bulk-upload-header'] : attr;
}

function getCsvValue(attr, schema, value) {
  if (_.isNull(value) ||
    (schema.properties[attr].bulkDeleteValueIfEmpty && ((_.isString(value) && _.isEmpty(value.trim())) || _.isEmpty(value)))) {
    return 'delete_value';
  }

  var func = schema.properties[attr]['bulk-upload-mapper'];
  value = !_.isFunction(func) ? value : func(value);

  if (_.contains(value, '"')) {
    value = value.replace(/"/g, '""');
  }

  if (_.contains(value, ',') || _.contains(value, '"')) {
    value = '"' + value + '"';
  }

  return value;
}

//traversing fields for each set of attributes in the list, returning:
//- currentRow: data to send to MT for the given object(csv header names and values)
//- nextRoots: inner objects that need to be traversed again for new data (like when Campaign includes AdGroups)
//- nextRootSchemas: Schemas to be used for the next inner objects (based on their type).
//- attributes: attributes that are passed to the function with the client Ids added to them.
function traverseFields(attrs, schema) {
  var currentRow        = {},
      nextRoots         = [],
      nextRootSchemas   = [];

  _.each(attrs, function(value, attr) {
    if (attr === 'Client Id') {
      currentRow[attr] = value;
      return;
    }

    //reject the function if there is any attribute not found in the schema
    if (!_.has(schema.properties, attr)) {
      throw new Error('invalid property ' + attr + ' is passed!');
    }

    var innerPropertySchema = null;
    //deciding if the current attr has schema
    if (schema.properties[attr].type === 'object'
    || schema.properties[attr].type === 'array') {
      innerPropertySchema = getSchemaOfSubAttr(schema, attr);
    };


    if (innerPropertySchema) {
      //skipping the budget schema as it has the Id but it shouldn't be considered as a root entity
      //todo [imang]: this should be a temporary fix. We have to think this through and find the optimal solution to find root entities
      // todo: SharedEntityAssociation is root, however it has EntityId and SharedEntityId, but not Id
      var isRootEntity = (getSchemaName(innerPropertySchema) === 'SharedEntityAssociation')
        || (getSchemaName(innerPropertySchema) !== 'Budget'
        && getSchemaName(innerPropertySchema) !== 'AssetLink'
        && _.has(innerPropertySchema.properties, 'Id'));

      //deciding if the attr is a foreign key link or a complete object needs to be further traversed
      var attrKeys = _.keys(value);
      var isObjectAForeignKey = schema.properties[attr].type === 'object' && _.size(attrKeys) === 1 && attrKeys[0] === 'Id';
      if (isRootEntity) {
        if (isObjectAForeignKey) {
          //todo [imang]: currently the only relationship defined and handled in the adapter is the Parent Relationship. Should make this more generic in the future.
          currentRow['Parent ID'] = value['Id'];
        } else {
          nextRoots.push(attr);
          nextRootSchemas.push(innerPropertySchema);
        }
      } else if (schema.properties[attr]['bulk-upload-mapper']) {
        // check if it needs custom mapping
        currentRow[getCsvHeaderName(attr, schema)] = getCsvValue(attr, schema, value);
      } else if (getSchemaOfSubAttr(schema, attr)['bulk-upload-full-mapper']) {
        if (_.isFunction(getSchemaOfSubAttr(schema, attr)['bulk-upload-full-mapper'])) {
          var keyValues = getSchemaOfSubAttr(schema, attr)['bulk-upload-full-mapper'](value);
          _.each(keyValues, function(pair) {
            currentRow[pair.key] = pair.value;
          });
        }
      } else {
        //if the loaded schema is not representing a root entity
        //extract the values from inside and populate to the current row object (e.g. Budget)
        //TODO [imang]: currently only supports one level traversal
        // TODO [imang]: handling special case for the Budget entity that has other properties that should be skipped.
        var propsToIterate = getSchemaName(innerPropertySchema) === 'Budget' ? _.pick(innerPropertySchema.properties, 'Amount', 'Type') : innerPropertySchema.properties;
        for (var prop in propsToIterate) {
          currentRow[getCsvHeaderName(prop, innerPropertySchema)] = getCsvValue(prop, innerPropertySchema, value[prop]);
        }
      }
    } else {
     if (schema.properties[attr]['bulk-upload-flattened']) {
        if (schema.properties[attr]['bulk-upload-enum']) {
          var enumIndex = schema.properties[attr].enum.indexOf(value);
          var csvHeaderName = schema.properties[attr]['bulk-upload-enum'][enumIndex];
          currentRow[csvHeaderName] = 'ON';
        }
      } else if (_.isFunction(schema.properties[attr]['bulk-upload-full-mapper'])) {
        var pairs = schema.properties[attr]['bulk-upload-full-mapper'](value);
        _.each(pairs, function(pair) {
          currentRow[pair.key] = pair.value;
        });
      } else {
        currentRow[getCsvHeaderName(attr, schema)] = getCsvValue(attr, schema, value);
      }
    }
  });

  return {
    attributes      : attrs,
    currentRow      : currentRow,
    nextRoots       : nextRoots,
    nextRootSchemas : nextRootSchemas
  };
}

function getCsvColumns(rows) {
  return _.uniq(_.union(_.flatten(_.map(rows, function(row) {return _.keys(row);})), ['Id', 'Parent ID']));
}

function generateCsvRows(rows) {
  var columns = getCsvColumns(rows),
      result = [];
  //first line is the list of columns
  var line = columns.join();
  result.push(line);

  _.each(rows, function(row) {
    line = '';
    _.each(columns, function(column) {
      line += ((_.has(row, column) && !_.isUndefined(row[column])) ? row[column] : '') + ',';
    });
    line = line.slice(0, -1);
    result.push(line);
  });
  return result;
}

function convertCsvDataInTextToCsvArray(csvData) {
  var csvDataArray = [];
  var csvDataRows = [];
  var pos = 0;

  while (pos < csvData.length - 1) {
    csvDataArray.push(csvData.slice(pos, csvData.indexOf('\n', pos) - 1));
    pos = csvData.indexOf('\n', pos) + 1;
  }

  _.each(csvDataArray, function(csvDataRow) {
    var rowData = csvDataRow.split(',');
    csvDataRows.push(rowData);
  });

  return csvDataRows;
}

export function parse(csvData, isTextType) {
  var results = [];

  if (!_.isUndefined(isTextType)) {
    csvData = convertCsvDataInTextToCsvArray(csvData);
  }

  var headers = csvData[0];
  //first line is header, second line is format version. needs to be skipped.
  var data = csvData.slice(2);
  _.each(data, function(row) {
    var obj = {};
    for (var col = 0; col < row.length; col++) {
      obj[headers[col]] = row[col];
    }
    results.push(obj);
  });

  var errors = getErrorRows(results);
  return {
    results: results,
    errors: errors
  };
}

export function findEntityWithClientId(data, clientId) {
  if (!_.isArray(data)) {
    if (data['Client Id'] === clientId) {
      return data;
    } else {
      for (var attr in data) {
        if (_.isArray(data[attr])) {
          return findEntityWithClientId(data[attr], clientId);
        }
      }
    }
  } else {
    _.each(data, function(singleData) {
      var ret = findEntityWithClientId(singleData, clientId);
      if (ret) {
        return ret;
      }
    });
  }
  return null;
}

function setId(data, clientId, id) {
  if (!_.isArray(data)) {
    if (data['Client Id'] === clientId) {
      data['Id'] = id;
      //delete data['Client Id'];
      return;
    } else {
      for (var attr in data) {
        if (_.isArray(data[attr])) {
          setId(data[attr], clientId, id);
        }
      }
    }
  } else {
    _.each(data, function(singleData) {setId(singleData, clientId, id);});
  }
}

export function populateIds(csvData, clientData) {
  var ret = clientData;
  _.each(csvData, function(csvRow) {
    if (csvRow['Id']) {
      setId(ret, csvRow['Client Id'], csvRow['Id']);
    }
  });
  return ret;
}

function getErrorRows(csvData) {
  var errors = _.filter(csvData, function(item) {
    return item.Type.indexOf(' Error') > -1;
  });

  //Parsing error process-> Property-> EditorialCode->ErrorNumber->Error
  return _.map(errors || [], function (error) {
    return {
      'EditorialCode'     : error['Editorial Reason Code'] || '',
      'EditorialLocation' : error['Editorial Location'] || '',
      'EditorialTerm'     : error['Editorial Term'] || '',
      'ErrorNumber'       : error['Error Number'] || '',
      'Property'          : error['Field Path'] || '',
      'Error'             : error.Error || '',
      'Client Id'         : error['Client Id'] || '',
      'Type'              : error.Type || '',
      'Id'                : error.Id || '',
      'Parent Id'         : error['Parent Id'] || ''
    };
  });
}
