/**
 * The OData plugin module
 * @module component/edm-odata/index
 */
import _ from 'underscore';
import {
  hasOwnProperty,
  defineConstProperty,
  defineProducedPropertyOnClass,
} from '@bingads-webui/reflection';

import { odata } from '@bingads-webui/http-util';
import * as url from '@bingads-webui/url-util';
import { resIdsPlugin } from '@bingads-webui/edm-resource-identifiers';
import { odataCachePlugin } from './cache';

// support a mongoDB like orderby object for now: { age : -1, name: 1 }
// for backward compatibility, support array format: [['age', 'desc'], ['name', 'asc]]
function parseOrderBy(orderby) {
  function lowerCaseOrderValue(value) {
    return _.map(value, (v, index) => (index === 1 ? v.toLowerCase() : v));
  }

  if (_.isEmpty(orderby)) {
    return {};
  }

  let $orderby;
  if (_.isArray(orderby)) {
    $orderby = _.map(orderby, value => lowerCaseOrderValue(value).join(' ')).join(',');
  } else {
    $orderby = _.map(orderby, (value, key) => `${key} ${value > 0 ? 'asc' : 'desc'}`).join(',');
  }

  return {
    $orderby,
  };
}

// TODO: [yaoyao] add support to parse filter object
function parseFilter(filter) {
  if (_.isEmpty(filter) || _.isEmpty(filter.where)) {
    return {};
  }

  return {
    $filter: filter.where,
  };
}

function parseTop(top) {
  if (!top) {
    return {};
  }

  return {
    $top: top,
  };
}

function parseSkip(skip) {
  if (!skip) {
    return {};
  }

  return {
    $skip: skip,
  };
}

function parseCount(count) {
  if (count === undefined) {
    return {};
  }

  return {
    $count: count,
  };
}

function parseSelect(select, resource) {
  if (!select) return {};
  const parsed = {};
  const elementType = resource.type.elementType ? resource.type.elementType : resource.type;
  const { navigationPropertyNames } = elementType;
  const $select = select.filter(item => !_.contains(navigationPropertyNames, item)).join(',');
  const $expand = select.filter(item => _.contains(navigationPropertyNames, item)).join(',');

  if ($select.length) {
    parsed.$select = $select;
  }

  if ($expand.length) {
    parsed.$expand = $expand;
  }

  return parsed;
}

function parseSearch(search) {
  if (!search) {
    return {};
  }
  return { $search: search };
}

// TODO: make all the legacy findall call related logic to a separate edm plugin package
// backward compatibility for legacy grid jsdata resource finall call
const legacyJsDataResultProcess = (response) => {
  const result = response.value.slice();
  Object.defineProperty(result, 'totalCount', {
    value: response['@odata.count'],
    enumerable: false,
  });
  Object.defineProperty(result, 'raw', {
    value: response,
    enumerable: false,
  });
  return result;
};

/**
 * @param {EDM} edm - The EDM object to apply the plugin to
 * @param {String} rootURL - The root URL of the OData service
 * @param {object} dependants - The entity dependency relations
 * @param {object} processOptions - The options to process response and error
 * @return {Void} nothing to return
 */
export function odataPlugin(edm, rootURL, dependants = {}, {
  processResponse = _.identity,
  processError = _.identity,
  processOptions = _.identity,
} = {}) {
  const odataRootURL = rootURL.replace(/\/*$/, '');
  if (hasOwnProperty(edm, 'odata')) {
    return;
  }

  const wrapODataMethod = (method, resource) =>
    function wrappedODataMethod(odataURL, options) {
      return method(odataURL, processOptions(options, resource))
        .then(response => processResponse(response, resource))
        .catch((error) => {
          throw processError(error, resource);
        });
    };

  odataCachePlugin(edm, dependants, {
    fetchEntity: (resource, {
      select,
    }) => wrapODataMethod(odata.get, resource)(resource.odataURL, {
      data: {
        ...parseSelect(select, resource),
      },
    }),
    patchEntity: (resource, {
      select,
    }, update) => wrapODataMethod(odata.patch, resource)(resource.odataURL, {
      data: update,
      urlParameters: {
        ...parseSelect(select, resource),
      },
    }),
    createEntity: (collectionResource, {
      select,
    }, data) => wrapODataMethod(odata.post, collectionResource)(collectionResource.odataURL, {
      data,
      urlParameters: {
        ...parseSelect(select, collectionResource),
      },
    }),
    deleteEntity: resource => wrapODataMethod(odata.delete, resource)(resource.odataURL),
    fetchCollection: (collectionResource, {
      top,
      skip,
      count,
      orderby,
      filter,
      select,
      search,
      ...others
    }) => wrapODataMethod(odata.get, collectionResource)(collectionResource.odataURL, {
      data: {
        $top: top,
        $skip: skip,
        $count: count,
        ...parseOrderBy(orderby),
        ...parseFilter(filter),
        ...parseSelect(select, collectionResource),
        ...parseSearch(search),
        ...others,
      },
    }),
    action: (collectionResource, {
      top,
      skip,
      count,
      orderby,
      filter,
      select,
      payload,
    }) => wrapODataMethod(odata.post, collectionResource)(collectionResource.odataURL, {
      data: payload,
      urlParameters: {
        ...parseTop(top),
        ...parseSkip(skip),
        ...parseCount(count),
        ...parseOrderBy(orderby),
        ...parseFilter(filter),
        ...parseSelect(select, collectionResource),
      },
    }),
  });

  const {
    getEntity,
    postEntity,
    destroyEntityAsPromise,
    entityAction,
    getCollection,
    collectionAction,
  } = edm['odata.cache'];

  resIdsPlugin(edm);

  /**
   * @name edm
   * @property {Object} odata - A dummy indicator for odataPlugin being applied
   */
  defineConstProperty(edm, 'odata', (() => {
    const {
      ResourceIdentifier,
      CollectionResourceIdentifier,
      Navigation,
    } = edm.resourceIdentifiers;

    defineProducedPropertyOnClass(
      ResourceIdentifier, 'odataURL',
      /**
       * @memberof ResourceIdentifier#
       * @this ResourceIdentifier
       * @returns {String} The URL to access the OData resource
       */
      function () { // eslint-disable-line func-names
        return url.join(odataRootURL, this.path);
      }
    );

    // edm.currentCustomer.Accounts is an instance of ResourceIdentifier
    // usage:
    // edm.currentCustomer.Accounts.get({ top: 20, skip: 10 })
    // TODO:
    //   * type enrichment, for example, 'Active' => Enum.CampaignStatus'Active'
    //   * type check
    //   * cache and onChange event on response
    CollectionResourceIdentifier.prototype.get = function get({
      top = 20,
      skip = 0,
      count = true,
      // when null means default response
      // most likely it would includes all non navigation property and exclude all navigation ones
      select = null,
      //   we should avoid random query params like SegmentationTypes, startDate, endDate, etc
      filter = null,
      orderby = null,
      search = null,
      ...others
    } = {}, hardResetData, bypassCache) {
      return getCollection(this, {
        top,
        skip,
        count,
        select,
        filter,
        orderby,
        search,
        ...others,
      }, hardResetData, bypassCache);
    };

    ResourceIdentifier.prototype.get = function get({
      // when null means default response
      // most likely it would includes all non navigation property and exclude all navigation ones
      select = null,
    } = {}, hardResetData, bypassCache) {
      return getEntity(this, {
        select,
      }, hardResetData, bypassCache);
    };

    ResourceIdentifier.prototype.patch = function patch(data, {
      select = null,
    } = {}, bypassCache) {
      const cacheItem = getEntity(this, {
        select,
      }, bypassCache);
      return cacheItem.update(data);
    };

    // NOTE: likezh: DELETE operation returns a Promise, not an observable.
    // See comments in cache.js.
    ResourceIdentifier.prototype.destroyAsPromise = function destroy() {
      return destroyEntityAsPromise(this);
    };

    ResourceIdentifier.prototype.$_callableMakeCall = function $callableMakeCall({
      top,
      skip,
      count,
      orderby = null,
      filter = null,
      select = null,
      payload,
      hardResetData,
      bypassCache,
      // custom query options (only for FunctionType, for ActionType use payload for custom parameters)
      startDate,
      endDate,
    } = {}) {
      if (this.type instanceof edm.types.CollectionType) {
        if (this.navigation.source.type instanceof edm.types.ActionType) {
          return collectionAction(this, {
            top: top || 20,
            skip: skip || 0,
            count: count === undefined ? true : count,
            orderby,
            filter,
            select,
            payload,
          }, bypassCache);
        } else if (this.navigation.source.type instanceof edm.types.FunctionType) {
          return getCollection(this, {
            top: top || 20,
            skip: skip || 0,
            count: count === undefined ? true : count,
            orderby,
            filter,
            select,
            startDate,
            endDate,
          }, hardResetData, bypassCache);
        }
      }

      // In theory, we should not have param like top, count, filter and orderby
      // for none collectionType. But the Odata metadata have some incorrect information
      // that return type of collection is marked as none collection
      if (this.navigation.source.type instanceof edm.types.ActionType) {
        return entityAction(this, {
          top,
          skip,
          count,
          orderby,
          filter,
          select,
          payload,
        }, bypassCache);
      }

      return getEntity(this, {
        top,
        skip,
        count,
        orderby,
        filter,
        select,
      }, hardResetData, bypassCache);
    };

    ResourceIdentifier.prototype.$makeCall = function $makeCall({
      query,
      functionParams,
      payload,
      hardResetData,
      bypassCache,
    } = {}) {
      return this.$call(functionParams).$_callableMakeCall({
        ...query,
        payload,
        hardResetData,
        bypassCache,
      });
    };

    // align with jsdata, to ensure backward compatibility with jsdata data source.
    ResourceIdentifier.prototype.findAll = function findAll(query = {}) {
      if (this.type instanceof edm.types.ActionType) {
        return this.$makeCall({
          query: {
            top: query.limit || 20,
            skip: query.offset || 0,
            count: true,
            orderby: query.orderBy,
            filter: query.filter,
            select: query.select,
          },
          payload: query.query,
        }).toPromise().then(legacyJsDataResultProcess);
      }
      throw new Error('findall call for this type is not suported');
    };

    defineProducedPropertyOnClass(CollectionResourceIdentifier, 'idAttribute', function idAttribute() {
      return this.type.elementType.key;
    });

    CollectionResourceIdentifier.prototype.post = function post(data, {
      select = null,
    } = {}) {
      return postEntity(this, {
        select,
      }, data);
    };

    // usage:
    // edm.currentCustomer.Accounts.findAll({ limit: 20 }), align with jsdata
    // TODO:
    //   * refine available options, jsdata aligned arguments
    CollectionResourceIdentifier.prototype.findAll = function findAll(query = {}) {
      return this.get({ top: query.limit }).toPromise().then(legacyJsDataResultProcess);
    };

    // usage:
    // edm.currentCustomer.Accounts.update(123, { Name: 'another name' }), align with jsdata
    CollectionResourceIdentifier.prototype.update = function update(id, data) {
      return this.$withKey(id).patch(data).toPromise();
    };

    defineProducedPropertyOnClass(
      Navigation, 'odataURL',
      /**
       * @memberof Navigation#
       * @this Navigation
       * @returns {String} The URL to access the OData resource
       */
      function () { // eslint-disable-line func-names
        return url.join(odataRootURL, this.path);
      }
    );

    // put the plugin indicator
    return {
      odataRootURL,
    };
  })());
}
