import _ from 'underscore';
import * as safeJSON from '@bingads-webui/safe-json';
import * as urlUtil from './url';
import {
  ODataBatchOptions, HTTPResponse, HTTPResponses, StringDictionary, StringableDictionary,
} from '../types';

export function generate(options: ODataBatchOptions, boundary: string) {
  const lines = [];

  _.each(options.reqs, (req) => {
    const method = req.type.toUpperCase();
    const url = urlUtil.make(req.url, _.extend(options, {
      // NOFIX:
      // path: 'odata.multipart_path',
      path: options.multipartPath,
    }));
    lines.push(`--${boundary}`);

    // [imang]: the content-type and content-transfer-encoding headers
    // must be present for delete reqs inside a batch call
    if (method !== 'GET' || options.needContentType /* && method != 'DELETE' */) {
      lines.push(`Content-Type: ${req.contentType || 'application/http'}`);
      lines.push(`Content-Transfer-Encoding: ${req.contentTransferEncoding || 'binary'}`);
    }

    lines.push('', `${method} ${url} HTTP/1.1`);

    // req.headers is used to support all kinds of data headers of every request in batch call body
    if (!_.isUndefined(req.headers)) {
      // the following is meant to ingnore the specific headers which are already supported by req.dataContentType
      // and options.authorization.
      // for any other headers, please use req.headers. don't add another specific option to support.
      const ignored: string[] = [];
      if (!_.isNull(req.data) && !_.isUndefined(req.data)) {
        ignored.push('Content-Type');
        if (options.authorization) {
          ignored.push('Authorization');
        }
      }

      _.each(req.headers as StringableDictionary, (value, key) => {
        if (ignored.indexOf(key) === -1) {
          lines.push(`${key}: ${value}`);
        }
      });
    }

    if (!_.isNull(req.data) && !_.isUndefined(req.data)) {
      lines.push(`Content-Type: ${req.dataContentType || 'application/json; charset=utf-8'}`);
      if (options.authorization) {
        lines.push(`Authorization: ${options.authorization}`);
      }
      lines.push('', JSON.stringify(req.data));
    }

    lines.push('\r\n');
  });

  if (lines.length) {
    lines.push(`--${boundary}--`, '');
  }

  return lines.join('\r\n');
}

function parseHttpResponse(responseString: string) {
  const lines = responseString.split(/\r?\n/);
  const statusLine = <string>lines.shift();

  // parse header lines
  const headers: StringDictionary = {};
  let headerParts: string[];
  let headerLine: string;
  let key: string;

  while (lines.length > 0) {
    headerLine = <string>lines.shift();

    if (headerLine === '') {
      break;
    }

    headerParts = headerLine.split(':');
    key = <string>headerParts.shift();

    headers[key] = headerParts.join(':').trim();
  }

  const response: HTTPResponse = {
    headers,
    data: safeJSON.parseSync(lines.join('')),
  };

  // parse status line
  const parts = statusLine.match(/^(.+) ([0-9]{3}) (.*)$/);

  if (parts) {
    const [, protocol, status, statusMessage] = parts;
    response.protocol = protocol;
    response.status = parseInt(status, 10);
    response.statusMessage = statusMessage;
  }

  return response;
}

export function parse(text = '') {
  const lines = text.split('\r\n');
  const boundary = lines[0];
  const responses: HTTPResponse[] = [];
  let tmp: string[];
  let scanStatus = 0;

  _.each(lines, (line) => {
    if (line.indexOf(boundary) === 0) {
      if (scanStatus === 2) {
        responses.push(parseHttpResponse(tmp.join('\r\n')));
      }
      // a new response begin
      scanStatus = 1;
      tmp = [];
    } else if (scanStatus === 1 && line === '') {
      // real response begin
      scanStatus = 2;
    } else if (scanStatus === 2) {
      tmp.push(line);
    }
  });

  const successCodeList = [200, 204];
  const statusCounter = _.chain(responses)
    .countBy((val) => (_.contains(successCodeList, val.status) ? 'success' : 'failure'))
    .defaults({
      success: 0,
      failure: 0,
    })
    .value();

  Object.defineProperty(responses, 'statusCounter', {
    value: statusCounter,
    enumerable: false,
  });

  return <HTTPResponses>responses;
}
