import _ from 'underscore';
import { Base } from './base';

/**
 * Enum validator, which check whether value is in enumators
 * @param {array} enumerators - possible values
 * @param {I18n} i18n - provide getString callback, will be called in Base class
 */
class Enum extends Base {
  constructor(enumerators, i18n) {
    super(i18n);

    this.enumerators = enumerators.slice();
  }

  isValidValue(value) {
    return _.contains(this.enumerators, value);
  }
}

/**
 * Create a enum validator instance
 * @param {array} enumerators - possible values
 * @param {I18n} i18n - provide getString callback, will be called in Base class
 * @returns {Validator} - return a enum validator
 */
export function enumeration(enumerators, i18n) {
  return new Enum(enumerators, i18n);
}
