import _ from 'underscore';
import * as odataCast from './cast';

function getCast({ cast = null }) {
  return _.isEmpty(cast) ? '' : `/${cast}`;
}

function getParent({ parent = null }) {
  return _.isEmpty(parent) ? '' : `${parent}/`;
}

// TODO: wewei, ericwa, remove the parent/cast in existing code
function getUriLegacy(definition, options, id) {
  if (options.url) {
    return options.url;
  }

  const name = `${definition.name}s`;
  const idType = _.chain(definition)
    .result('schema')
    .result('properties')
    .result(definition.idAttribute)
    .result('type')
    .value();

  if (!_.isNull(id)) {
    if (_.isString(id) && (idType === 'string' || !idType)) {
      return `/Customers(:advertisercustomer_id)/Accounts(:account_id)/${getParent(options)}${name}('${id}')${getCast(options)}`;
    }

    return `/Customers(:advertisercustomer_id)/Accounts(:account_id)/${getParent(options)}${name}(${id})${getCast(options)}`;
  }

  return `/Customers(:advertisercustomer_id)/Accounts(:account_id)/${getParent(options)}${name}${getCast(options)}`;
}

export function convert({
  definition,
  options = {},
  id = null,
  isFinding = false,
  entitySet = options.entitySet,
}) {
  if (entitySet) {
    // NOTICE: Change made here to pass in advertiserCustomerID
    // instead of pulling from component/state.
    const { useAdvertiserCustomerId = true, advertiserCustomerID } = options;
    const searchId = useAdvertiserCustomerId && entitySet.Accounts ?
      advertiserCustomerID :
      id;

    let resIds = _.isNull(searchId) ? entitySet : entitySet.$withKey(searchId);

    if (isFinding) {
      resIds = odataCast.resIds({ resIds, definition, options });
    }

    return resIds.odataURL;
  }
  return getUriLegacy(definition, options, id);
}
