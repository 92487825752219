import _ from 'underscore';
import * as putil from '@bingads-webui-universal/primitive-utilities';
import { Base } from './base';
import * as number from './string-number';

export { number };
/**
 * Get length of a string, if disgintuish CJK in string, each CJK character counts as 2 charts
 * otherwise, count as 1 charts
 * @param {string} str - string to compute length
 * @param {boolean} distinguishCJK - whether disgintuish CJK in string,
 * if not, the function equal to _.size, default value is false
 * @returns {int} - the length of string
 */
export function getLength(str, distinguishCJK = false) {
  if (!distinguishCJK) {
    return _.size(str);
  }

  let counts = _.countBy(str, c => (putil.hasCJK(c) ? 'cjk' : 'ascii'));

  counts = _.defaults(counts, {
    cjk: 0,
    ascii: 0,
  });

  return (counts.cjk * 2) + counts.ascii;
}

/**
 * Define a validator check type of value is string
 * @param {I18n} i18n - will be called in Base class
 */
class Type extends Base {
  isValidValue(value) {
    return _.isString(value);
  }
}

/**
 * Define a validator check size of string
 * @param {int} length - the max length of string
 * @param {I18n} i18n - will be called with args 'Validation_String_Max_Length'
 * @param {boolean} distinguishCJK - whether distinguish CJK in size check,
 * if true, one CJK character count 2
 */
class Size extends Base {
  constructor(length, i18n, distinguishCJK = true) {
    super(i18n);

    this.blockInput = false;
    this.length = length || 0;
    this.distinguishCJK = distinguishCJK;
    this.message = i18n.getString('Validation_String_Max_Length', { maxlength: this.length });
  }

  isValidValue(value) {
    return _.isString(value) && putil.getLength(value, this.distinguishCJK) <= this.length;
  }
}

/**
 * Define a validator check size of string
 * @param {int} length - the min length of string
 * @param {I18n} i18n - will be called with args 'Validation_String_Min_Length'
 * @param {boolean} distinguishCJK - whether distinguish CJK in size check,
 * if true, one CJK character count 2
 */
class MinSize extends Base {
  constructor(length, i18n, distinguishCJK = true) {
    super(i18n);

    this.blockInput = false;
    this.length = length || 0;
    this.distinguishCJK = distinguishCJK;
    this.message = i18n.getString('Validation_String_Min_Length', { minlength: this.length });
  }

  isValidValue(value) {
    return _.isString(value) && putil.getLength(value, this.distinguishCJK) >= this.length;
  }
}

/**
 * Define a validator check whether value is XSS code
 * @param {I18n} i18n - will be called with args 'Validation_String_Invalid_Characters'
 */
class XSS extends Base {
  constructor(i18n) {
    super(i18n);

    this.message = i18n.getString('Validation_String_Invalid_Characters');
  }

  isValidValue(value) {
    return _.isString(value) && !(/<[^>]*>/).exec(value);
  }
}

/**
 * Define a validator check whether value match pattern
 * @param {string|RegExp} pattern - a pattern to test value
 * @param {I18n} i18n - will be called with args 'Validation_String_Invalid_Characters'
 */
class Pattern extends Base {
  constructor(patt, i18n) {
    super(i18n);

    this.pattern = _.isString(patt) ? new RegExp(patt) : patt;
    this.message = i18n.getString('Validation_String_Invalid_Characters');
  }

  isValidValue(value) {
    return this.pattern.test(value);
  }
}

/**
 * Create string type validate instance
 * @param {I18n} i18n - provide callback getString to get error message
 * @return {Validator} - string type validator
 */
export function type(i18n) {
  return new Type(i18n);
}

/**
 * Create string size validate instance
 * @param {int} length - the max length of string
 * @param {boolean} distinguishCJK - whether distinguish CJK in size check
 * @param {I18n} i18n - provide getString to get error message
 * @returns {Validator} - string size validator
 */
export function size(length, distinguishCJK, i18n) {
  return new Size(length, i18n, distinguishCJK);
}

/**
 * Create string size validate instance
 * @param {int} length - the min length of string
 * @param {boolean} distinguishCJK - whether distinguish CJK in size check
 * @param {I18n} i18n - provide getString to get error message
 * @returns {Validator} - string size validator
 */
export function minSize(length, distinguishCJK, i18n) {
  return new MinSize(length, i18n, distinguishCJK);
}

/**
 * Create a validator check whether value is XSS code
 * @param {I18n} i18n - will be called with args 'Validation_String_Invalid_Characters'
 * @returns {Validator} - string xss validator
 */
export function xss(i18n) {
  return new XSS(i18n);
}

/**
 * Create a validator check whether value match pattern
 * @param {string|RegExp} patt - a pattern to test value
 * @param {I18n} i18n - will be called with args 'Validation_String_Invalid_Characters'
 * @returns {Validator} - string pattern validator
 */
export function pattern(patt, i18n) {
  return new Pattern(patt, i18n);
}
