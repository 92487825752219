import { noop } from 'underscore';
import { Observable } from '@bingads-webui-universal/observable';

/**
 * make a server call and setup the cache item state
 * @param {Function} call function returns a Promise of data
 * @returns {Promise} a resolved promise
 */
function makeCall(call) {
  this.state.status = 'PENDING';
  return call()
    .then((data) => {
      this.setData(data);

      return null;
    })
    .catch((err) => {
      this.observers.forEach((item) => {
        item.error(err);
      });
      this.state.status = 'ERROR';
      this.state.error = err;
    });
}

class CacheItem extends Observable {
  constructor({
    fetch,
    afterSubscribe = noop,
    afterUnsubscribe = noop,
    hardResetCacheData,
  }) {
    super((observer) => {
      this.observers.add(observer);
      this.afterSubscribe(this);
      if (this.state.status === 'WAITING') {
        if (hardResetCacheData) {
          this.setData(hardResetCacheData);
        } else {
          this.invalidate();
        }
      } else if (this.state.status === 'READY') {
        observer.next(this.state.data);
      } else if (this.state.status === 'ERROR') {
        observer.error(this.state.error);
      }
      return () => {
        this.observers.delete(observer);
        this.afterUnsubscribe(this);
      };
    });
    this.fetch = fetch;
    // eslint-disable-next-line no-undef
    this.observers = new Set();
    this.afterSubscribe = afterSubscribe;
    this.afterUnsubscribe = afterUnsubscribe;
    this.state = {
      // WAITING - before any subscription
      // PENDING - has subscription, call made but not responded
      // READY - already has data
      // ERROR - already has error
      status: 'WAITING',
      data: null,
      error: null,
    };
  }

  setData(data) {
    this.observers.forEach((item) => {
      item.next(data);
    });
    this.state.status = 'READY';
    this.state.data = data;

    return null;
  }

  invalidate() {
    if (this.observers.size === 0) {
      this.state.status = 'WAITING';
    } else {
      makeCall.call(this, () => this.fetch());
    }
  }

  hasObservers() {
    return this.observers.size > 0;
  }
}

export class CollectionCacheItem extends CacheItem {
}

class PatchEntityCacheItem extends Observable {
  constructor() {
    super((observer) => {
      this.observers.add(observer);
      if (this.state.status === 'READY') {
        observer.next(this.state.data);
      } else if (this.state.status === 'ERROR') {
        observer.error(this.state.error);
      }
      return () => {
        this.observers.delete(observer);
      };
    });
    // eslint-disable-next-line no-undef
    this.observers = new Set();
    this.state = {
      // WAITING - before any subscription
      // READY - already has data
      // ERROR - already has error
      status: 'WAITING',
      data: null,
      error: null,
    };
  }

  next(data) {
    this.observers.forEach((item) => {
      item.next(data);
    });
    this.state.status = 'READY';
    this.state.data = data;
  }

  error(err) {
    this.observers.forEach((item) => {
      item.error(err);
    });
    this.state.status = 'ERROR';
    this.state.error = err;
  }
}

export class EntityCacheItem extends CacheItem {
  constructor({
    fetch,
    patch,
    afterSubscribe = noop,
    afterUnsubscribe = noop,
    hardResetCacheData,
  }) {
    super({
      fetch,
      afterSubscribe,
      afterUnsubscribe,
      hardResetCacheData,
    });
    this.patch = patch;
  }

  update(update) {
    const patchEntityCacheItem = new PatchEntityCacheItem();

    const originalStatus = this.state.status;
    this.state.status = 'PENDING';
    this.patch(update)
      .then((data) => {
        this.observers.forEach((item) => {
          item.next(data);
        });
        this.state.status = 'READY';
        this.state.data = data;
      })
      .catch((err) => {
        patchEntityCacheItem.error(err);
        this.state.status = originalStatus;
      });

    this.subscribe({
      next: data => patchEntityCacheItem.next(data),
      error: err => patchEntityCacheItem.error(err),
    });

    return patchEntityCacheItem;
  }
}
