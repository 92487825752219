import _ from 'underscore';

import {
  AccountLinkPermissionType,
  MultiAgencyHeaderHide,
} from './constants';

export const isMultiAgencyBillingEnabled = permissions => _.get(permissions, ['dynamic', 'MultiAgencyBilling'], false);

export const getAccountLinkPermissionType = settingsContext => _.get(settingsContext, 'AccountLinkPermissionType');

export const isValidLinkPermission = settingsContext => _.contains(_.values(AccountLinkPermissionType), getAccountLinkPermissionType(settingsContext));
export const isStandardLinkPermission = settingsContext => getAccountLinkPermissionType(settingsContext) === AccountLinkPermissionType.Standard;
export const isBillingLinkPermission = settingsContext => getAccountLinkPermissionType(settingsContext) === AccountLinkPermissionType.Billing;

export const getHeaderHide = settingsContext => MultiAgencyHeaderHide[getAccountLinkPermissionType(settingsContext)] || {};
