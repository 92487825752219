import _ from 'underscore';
import $ from 'jquery';
import URI from 'urijs';
import topDomainList from './top-domain';

export function join(first: string, ...frags: string[]) {
  return frags.reduce((memo, frag) => {
    if (_.isEmpty(frag)) {
      return memo;
    }
    const eSlash = /\/$/.test(memo);
    const sSlash = /^\//.test(frag);

    if (!eSlash && !sSlash) {
      return `${memo}/${frag}`;
    } if (eSlash && sSlash) {
      return memo + frag.substring(1);
    }
    return memo + frag;
  }, first);
}

type QueryObject = any[] | JQuery.PlainObject | JQuery;

export function queryify(url: string, data: QueryObject, serializeParam = (originalData: QueryObject) => $.param(originalData)) {
  const startOfRoute = url.indexOf('#');
  let route = '';
  let pathAndQuery = url;

  if (startOfRoute !== -1) {
    route = url.substring(startOfRoute);
    pathAndQuery = url.substring(0, startOfRoute);
  }

  // $.param is not supported by node, so use custom param serialization function
  return pathAndQuery + (pathAndQuery.indexOf('?') === -1 ? '?' : '&') + serializeParam(data) + route;
}

export function mailto(recipient: string, subject: string, body: string) {
  return `mailto:${recipient}?subject=${subject}&body=${body}`;
}

export function setUrlParameter(name: string, value: string, url = window.location.href, options = { keepSqBrackets: false }) {
  const keepSqBrackets = !!options.keepSqBrackets;

  const setQueryStringParam = function (innerUrl: string, originalN: string, v: string, replaceOnly: boolean) {
    let n = originalN;
    if (!keepSqBrackets) {
      n = n.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
    }
    const regex = new RegExp(`([\\?&])${n}=.*?(&|$|#)`);
    if (innerUrl.match(regex)) {
      return innerUrl.replace(regex, `$1${n}=${v}$2`);
    }
    if (!replaceOnly) {
      let qs = '';
      const position = innerUrl.indexOf('?');
      if (position > -1) {
        if (position + 1 < innerUrl.length) {
          qs = '&';
        }
      } else {
        qs = '?';
      }
      return `${innerUrl + qs + n}=${v}`;
    }
    return innerUrl;
  };

  const pound = url.lastIndexOf('#');
  if (pound > -1) {
    return setQueryStringParam(url.slice(0, pound), name, value, false)
      + setQueryStringParam(url.slice(pound), name, value, true);
  }

  return setQueryStringParam(url, name, value, false);
}

// Gets the url parameter value by parameter name.
export function getParameter(originalName: string, url = window.location.href) {
  const name = originalName.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
  const value = (RegExp(`[\\?&]${name}=([^&#]*)`).exec(url) || [null, null])[1];
  if (value) return decodeURIComponent(value);
  return value;
}

// Update the url parameter by parameter name and value.
export function updateParameter(key: string, value: string, originalUrl: string) {
  const re = new RegExp(`([?|&])${key}=.*?(&|#|$)`, 'i');
  let url = originalUrl;
  if (url.match(re)) {
    return url.replace(re, `$1${key}=${value}$2`);
  }

  let hash = '';
  if (url.indexOf('#') !== -1) {
    hash = url.replace(/.*#/, '#');
    url = url.replace(/#.*/, '');
  }
  const separator = url.indexOf('?') !== -1 ? '&' : '?';
  return `${url + separator + key}=${value}${hash}`;
}

export function hasScheme(url: string) {
  // eslint-disable-next-line prefer-regex-literals
  const urlRegex = new RegExp('^(http|https|ftp)(://)(.+)', 'i');
  return urlRegex.test(url);
}

export function hasProtocol(url: string) {
  // eslint-disable-next-line prefer-regex-literals
  const urlRegex = new RegExp('^(http|https|ftp)(://)', 'i');
  return urlRegex.test(url);
}

export function extendPath(url: string, path: string) {
  const queryIndex = url.indexOf('?');
  if (queryIndex === -1) {
    return join(url, path);
  }

  return join(url.slice(0, queryIndex), path) + url.slice(queryIndex);
}

export function getDomain(originalUrl: string) {
  let url = originalUrl;
  if (!_.isString(url) || _.isEmpty(url.trim())) {
    return '';
  }

  url = url.trim();
  const a = document.createElement('a');
  let domain = '';
  a.href = hasProtocol(url) ? url : (`http://${url}`);

  try {
    const { hostname } = a;
    const startIndex = url.toLowerCase().indexOf(hostname);

    domain = (startIndex === -1 ? '' : url.substring(startIndex, hostname.length + startIndex));
  } catch (err) {
    domain = '';
  }

  return domain;
}

export function isRelativeUrl(url: string) {
  return _.isString(url) && (url.indexOf('/') === 0);
}

export function isInternalUrl(url: string, domainsToCheck = ['ads.microsoft.com', 'ads-int.microsoft.com', 'corp.microsoft.com']) {
  if (isRelativeUrl(url)) {
    return true;
  }

  const domain = getDomain(url).toLowerCase();

  return (domain === 'localhost')
    || _.any(domainsToCheck, (toCheck) => domain.indexOf(toCheck.toLowerCase()) > -1);
}

export function getUrlWithProtocol(website: string) {
  const site = website.trim();
  const uri = new URI(site);

  if (uri.protocol()) {
    return uri.toString();
  }

  // input path without protocal, add '//' manually to force abs path;
  const urlWithProtocal = URI(`${'//'}${site}`).protocol('https').toString();
  return urlWithProtocal;
}

export function isValidUrlWithValidTopDomain(url: string) {
  const regex = /^https?:\/\/([a-z0-9-_]+\.)*[a-z0-9-_]+\.([a-z]{2,63})(?=$|\/|\?)/i;
  const matchs = url.match(regex);
  const topLevelDomain = _.result(matchs, '2');
  return _.isString(url) && regex.test(url)
    && topLevelDomain && _.contains(topDomainList, topLevelDomain.toUpperCase());
}

// Regular expressions used to determine if a URL starts with a valid domain name.
// Similar regex to Microsoft.Ads.Mca.Implementation.Ads.EO.ValidationHelper.ValidateWebsite
export const UrlRegex = /^https?:\/\/([a-z0-9-_]+\.)*[a-z0-9-_]+\.[a-z]{2,63}(?=$|\/|\?)/i;
export const UrlRegexWithOptionalProtocol = /^(?:http(s)?:\/\/)?([a-z0-9-_]+\.)*[a-z0-9-_]+\.[a-z]{2,63}(?=$|\/|\?)/i;

/**
 * Validate a HTTP or HTTPS URL.
 * @param {String} url - Fully qualified URL, including the prefix. E.g. http://www.example.com/foo
 * @returns {Boolean} - True if the URL is valid.
 */
export function isValidUrl(url: string) {
  return _.isString(url) && UrlRegex.test(url);
}

/**
 * Extracts the protocol and domain from a URL, with no trailing slash.
 * @param {String} url - URL to extract from, e.g. 'https://abc-123.com/foo.html'
 * @returns {String} - Protocol and domain name, or null if url could not be parsed.
 */
export function getUrlProtocolAndDomain(url: string) {
  const matches = url.match(UrlRegex);
  if (matches) {
    const result = _.first(matches);
    return result || '';
  }
  return '';
}
