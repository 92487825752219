import _ from 'underscore';
import * as putil from '@bingads-webui-universal/primitive-utilities';
import * as core from './core';
import * as urlUtil from '../util/url';
import { Args, ODataOptions } from '../types';

export default function ajax<Options extends ODataOptions<TResult>, TResult>(...args: Args<Options, TResult>) {
  const [url, options, cb] = core.signature<Options, TResult>(args);

  // NOFIX:
  // if (!options.host) {
  //   options.host = 'odata.url';
  // }

  // if (!options.path) {
  //   options.path = 'odata.path';
  // }

  const uri = urlUtil.make(url, {
    host: options.host,
    path: options.path,
    // NOFIX:
    // use_state: options.use_state,
    params: options.params,
  });

  options.beforeSend = <(jqXHR: JQuery.jqXHR, settings: Options) => void>_.wrap(options.beforeSend || _.noop, (func, jqXHR) => {
    // All these setRequestHeader logic could be handled by `options.headers`
    // These code and related options are deprecated

    // NOFIX:
    // var oDataToken = config.get('odata.token');
    // NOTICE: oDataToken changed to come from options
    const { oDataToken, oDataTokenType } = options;

    if (oDataToken) {
      const tokenType = oDataTokenType || 'CCMTSmallToken';
      jqXHR.setRequestHeader('Authorization', `${tokenType} ${oDataToken}`);
    }

    // NOFIX:
    // var loginType = config.get('odata.loginType');
    // NOTICE: loginType changed to come from options
    const { loginType } = options;
    if (loginType) {
      jqXHR.setRequestHeader('x-ms-logintype', loginType);
    }

    // enable odata annotations by default;
    // we use annotations to return aggregates in grid responses
    jqXHR.setRequestHeader('Prefer', 'odata.include-annotations="*"');

    const requestId = putil.generateGuid();
    jqXHR.setRequestHeader('x-ms-requestid', requestId);

    const { applicationName } = options;

    jqXHR.setRequestHeader('x-ms-applicationname', applicationName || 'bingadsweb');

    const { headers } = options;
    // NOFIX:
    // var sessionId = _.result(config.get('Omniture'), 'SessionId');
    // JFX_SessionId is a secure HttpOnly cookie, inaccessible to client side cookie.
    // Changed from cookie to come from options.
    const sessionId = options.SessionId;
    if (!_.isUndefined(sessionId)) {
      jqXHR.setRequestHeader('x-ms-sessionid', sessionId);
    } else if (headers && headers['x-ms-sessionid']) {
      jqXHR.setRequestHeader('x-ms-sessionid', headers['x-ms-sessionid']);
    }

    // NOFIX:
    // We could pass in all these from `options.headers`
    // var pageContext = config.attributes();
    // if (!_.isUndefined(pageContext)) {
    //   jqXHR.setRequestHeader('x-ms-lcid', pageContext.CultureLcid);
    //   jqXHR.setRequestHeader('lcid', pageContext.CultureLcid);
    // }

    if (options.customHeader) {
      jqXHR.setRequestHeader(options.customHeader.name, options.customHeader.value);
    }
    // call the original func we are wrapping here
    if (_.isFunction(func)) {
      return func(jqXHR);
    }

    return undefined;
  });

  options.parse = (err) => err.value;

  // TODO: this logic is specific to Campaign OData
  options.i18nErrors = (errs, i18n) => {
    if (errs) {
      return _.map(errs, (err) => {
        let key = `Error_Campaign_API_${err.Code}`;
        if (err['@odata.type'] === '#Model.ApiEditorialError') {
          key = `Error_Editorial_Raw_${err.Code}`;
        }
        return i18n.getStringOrDefault(key, i18n.getString('Error: ') + err.Code);
      });
    }
    return [];
  };

  if (_.isUndefined(options.contentType)) {
    options.contentType = 'application/json';
  }

  return core.ajax<Options, TResult>(uri, options, cb);
}
