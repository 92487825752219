import { I18nModel } from '@bingads-webui-universal/i18n-model';
import core from '@bingads-webui-universal/i18n-cldr-data-modern/core.json';
import iana from 'iana-tz-data/iana-tz-data.json';

export function setupI18nModel(locale) {
  I18nModel.loadCLDRData(core);
  I18nModel.loadTimeZoneData(iana);
  I18nModel.setGlobalLocale(locale);

  // callback for webpack dynamic import
  return ({ default: data }) => {
    I18nModel.loadCLDRData(data);

    return null;
  };
}
