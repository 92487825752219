const ENTITY_PATH_ENUM = {
  'Model.ShoppingCampaign': 'Campaigns',
  'Model.Campaign': 'Campaigns',
  'Model.AdGroup': 'AdGroups',
};

export const getSkipEntityCallPaths = (entityName, ids) => {
  const entityPath = entityName.includes('Model') ? ENTITY_PATH_ENUM[entityName] : `${entityName}s`;
  if (!entityPath) {
    return [];
  }
  return ids.map(id => `${entityPath}(${id})`);
};
