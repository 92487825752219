import {
  defineConstProperty,
} from '@bingads-webui/reflection';
import stringify from 'json-stable-stringify';
import _ from 'underscore';
import Promise from 'bluebird';
import { CollectionCacheItem, EntityCacheItem } from './server-call-cache-item';
import { ObservableCache } from './observable-cache';
import { CacheEvent } from './cache-event';

export function odataCachePlugin(edm, dependants, {
  fetchEntity,
  patchEntity,
  createEntity,
  deleteEntity,
  fetchCollection,
  action,
}) {
  const entityDeps = dependants || {};

  defineConstProperty(edm, 'odata.cache', (() => {
    // TODO: when collection cache is ready, use the response to fill up entity cache
    const collectionCache = new ObservableCache();
    const cacheEvent = new CacheEvent();

    function getCollection(collectionResource, options, hardResetCacheData, bypassCache) {
      const typeName = collectionResource.type.elementType.name;
      const { path } = collectionResource;
      const stringifiedOptions = stringify(options);
      const cacheKeyPath = [typeName, path, stringifiedOptions];
      const item = collectionCache.get(cacheKeyPath);
      if (item && !bypassCache) {
        if (hardResetCacheData) {
          item.setData(hardResetCacheData);
        }
        return item;
      }
      const newItem = new CollectionCacheItem({
        fetch: () => fetchCollection(collectionResource, options),
        afterSubscribe: cache => collectionCache.observerAdded(cache, cacheKeyPath),
        afterUnsubscribe: cache => collectionCache.observerReduced(cache, cacheKeyPath),
        hardResetCacheData,
      });
      collectionCache.set(cacheKeyPath, newItem);
      return newItem;
    }

    function collectionAction(collectionResource, options, bypassCache) {
      const typeName = collectionResource.type.elementType.name;
      const { path } = collectionResource;
      const stringifiedOptions = stringify(options);
      const cacheKeyPath = [typeName, path, stringifiedOptions];
      const item = collectionCache.get(cacheKeyPath);
      if (item && !bypassCache) {
        return item;
      }
      const newItem = new CollectionCacheItem({
        fetch: () => action(collectionResource, options),
        afterSubscribe: cache => collectionCache.observerAdded(cache, cacheKeyPath),
        afterUnsubscribe: cache => collectionCache.observerReduced(cache, cacheKeyPath),
      });
      collectionCache.set(cacheKeyPath, newItem);
      return newItem;
    }

    const entityCache = new ObservableCache();

    function invalidateCollectionCache(typeName, skipEntityCallPaths) {
      collectionCache.invalidateObservedCache(typeName, skipEntityCallPaths);

      Object.keys(collectionCache.noObservers.entries).forEach((key) => {
        if (JSON.parse(key)[0] === typeName) {
          collectionCache.invalidateNoObserverCache(key);
        }
      });
    }

    function invalidateEntityCache(typeName, id, stringifiedOptions, skipEntityCallPaths) {
      const forAll = !id;

      if (entityCache.observedCache[typeName]) {
        if (forAll) {
          entityCache.invalidateObservedCache(typeName, skipEntityCallPaths);
        } else {
          entityCache.invalidateObservedCacheById(typeName, id, stringifiedOptions, skipEntityCallPaths);
        }
      }

      Object.keys(entityCache.noObservers.entries).forEach((key) => {
        const [type, parsedId, options] = JSON.parse(key);

        if (type === typeName
          && (forAll || (parsedId === id && options !== stringifiedOptions))) {
          entityCache.invalidateNoObserverCache(key);
        }
      });
    }

    function invalidate(typeName, id, stringifiedOptions, context, skipEntityCallPaths = null) {
      // eslint-disable-next-line no-param-reassign
      context[typeName] = true;
      invalidateCollectionCache(typeName, skipEntityCallPaths);
      invalidateEntityCache(typeName, id, stringifiedOptions, skipEntityCallPaths);

      _.each(entityDeps[typeName], (type) => {
        if (!context[type]) {
          // eslint-disable-next-line no-param-reassign
          context[type] = true;
          invalidate(type, null, '', context, skipEntityCallPaths);
        }
      });
      cacheEvent.notify('invalidate', typeName);
    }

    function invalidateTypeCache(typeName, id = null, stringifiedOptions = '', skipEntityCallPaths = null) {
      invalidate(typeName, id, stringifiedOptions, {}, skipEntityCallPaths);
    }

    function getEntity(resource, options, hardResetCacheData, bypassCache) {
      const typeName = resource.type.name;
      // entity odata function call will use the api, in this case we need to use path as hash key
      const idOrPath = resource.navigation.key || resource.path;
      const stringifiedOptions = stringify(options);
      const cacheKeyPath = [typeName, idOrPath, stringifiedOptions];
      const item = entityCache.get(cacheKeyPath);
      if (item && !bypassCache) {
        if (hardResetCacheData) {
          item.setData(hardResetCacheData);
        }
        return item;
      }
      const newItem = new EntityCacheItem({
        fetch: () => fetchEntity(resource, options),
        patch: update => patchEntity(resource, options, update)
          .tap(() => invalidateTypeCache(typeName, idOrPath, stringifiedOptions)),
        afterSubscribe: cache => entityCache.observerAdded(cache, cacheKeyPath),
        afterUnsubscribe: cache => entityCache.observerReduced(cache, cacheKeyPath),
        hardResetCacheData,
      });
      entityCache.set(cacheKeyPath, newItem);
      return newItem;
    }

    function entityAction(resource, options, bypassCache) {
      const typeName = resource.type.name;
      const { path } = resource.navigation;
      const stringifiedOptions = stringify(options);
      const cacheKeyPath = [typeName, path, stringifiedOptions];
      const item = entityCache.get(cacheKeyPath);
      if (item && !bypassCache) {
        return item;
      }
      const newItem = new EntityCacheItem({
        fetch: () => action(resource, options),
        afterSubscribe: cache => entityCache.observerAdded(cache, cacheKeyPath),
        afterUnsubscribe: cache => entityCache.observerReduced(cache, cacheKeyPath),
      });
      entityCache.set(cacheKeyPath, newItem);
      return newItem;
    }

    function postEntity(collectionResource, options, data) {
      const stringifiedOptions = stringify(options);
      const entityPromise = createEntity(collectionResource, options, data);
      const keyProperty = collectionResource.type.elementType.key;
      const typeName = collectionResource.type.elementType.name;
      const resourcePromise = entityPromise
        .then(entity => collectionResource.$withKey(entity[keyProperty]));
      let fetchFirstCalledAndPatchNotCalled = true;
      const newItem = new EntityCacheItem({
        fetch: () => {
          if (fetchFirstCalledAndPatchNotCalled) {
            fetchFirstCalledAndPatchNotCalled = false;
            return entityPromise;
          }
          return resourcePromise.then(resource => fetchEntity(resource, options));
        },
        patch: (update) => {
          fetchFirstCalledAndPatchNotCalled = false;
          return resourcePromise
            .tap(resource => invalidateTypeCache(
              typeName,
              resource.navigation.key,
              stringifiedOptions
            ))
            .then(resource => patchEntity(resource, options, update));
        },
      });
      resourcePromise.then((resource) => {
        const id = resource.navigation.key;
        const cacheKeyPath = [typeName, id, stringifiedOptions];

        newItem.afterSubscribe = cache => entityCache.observerAdded(cache, cacheKeyPath);
        newItem.afterUnsubscribe = cache => entityCache.observerReduced(cache, cacheKeyPath);
        entityCache.set(cacheKeyPath, newItem);
        invalidateTypeCache(typeName, id, stringifiedOptions);
      });
      return newItem;
    }

    function destroyEntityAsPromise(resource) {
      const typeName = resource.type.name;
      const id = resource.navigation.key;

      // NOTE: likezh: for delete, since the effect of deletion will be an entity being removed,
      // it is not useful to keep the call in cache. Hence we're directly returning a Promise here.
      return new Promise((resolve, reject) => {
        deleteEntity(resource)
          .then(() => {
            invalidateTypeCache(typeName, id, '');
            resolve();
          })
          .catch(err => reject(err));
      });
    }

    return {
      getEntity,
      postEntity,
      destroyEntityAsPromise,
      entityAction,
      getCollection,
      collectionAction,
      invalidateTypeCache,
      cacheEvent,
    };
  })());
}
