import {
  Observable,
  Observer,
  Subscription,
  SubscriptionObserver,
} from '@bingads-webui-universal/observable';
import _ from 'underscore';

interface IStateType {
  [key: string]: any;
}

export class StateStore<T = IStateType> {
  public state: T;

  protected subscriptions: Subscription[];

  protected observable: Observable;

  private isDefaultStateSet: boolean = false;

  protected set defaultState(dstate: T) {
    if (!this.isDefaultStateSet) {
      this.state = dstate;
      this.isDefaultStateSet = true;
    }
  }

  protected get defaultState() {
    return this.state;
  }

  constructor(initialState: T | undefined = undefined) {
    if (typeof initialState === 'object') {
      this.defaultState = initialState;
    }
    this.state = this.defaultState;
    this.subscriptions = [];
    this.observable = new Observable(this.subscriber);
  }

  setState = (stateChange: Partial<T>) => {
    if (this.shallowDiff(this.state, stateChange)) {
      this.state = _.extend({}, this.state, stateChange);
      this.subscriptions.forEach((subscription) => {
        subscription.observer.next(this.state);
      });
    }
  };

  subscribe(observer: Observer | ((value: T) => void)): Subscription {
    const subscription = this.observable.subscribe(
      this.ensureObserverType(observer),
    );
    this.subscriptions.push(subscription);

    return subscription;
  }

  private shallowDiff(left: any, right: any) {
    // eslint-disable-next-line no-restricted-syntax
    for (const prop in right) {
      // eslint-disable-next-line no-prototype-builtins
      if (!left.hasOwnProperty(prop) || left[prop] !== right[prop]) {
        return true;
      }
    }
    return false;
  }

  private subscriber = (observer: SubscriptionObserver) => () => {
    this.subscriptions = this.subscriptions.filter((subscription) => subscription.observer !== observer);
  };

  private ensureObserverType(ob: Observer | ((value: any) => void)): Observer {
    if (typeof ob !== 'function') {
      return ob;
    }
    return {
      start: (_value: any) => {},
      next: ob,
      error: (_errValue: any) => {},
      complete: () => {},
    };
  }
}
