import { cloneDeep } from '@bingads-webui-universal/primitive-utilities';

export default function () {
  let dataset = {};
  const MemoryStorage = {
    supportsStorage() {
      return true;
    },
    set(key, data) {
      dataset[key] = data;
    },
    get(key, deepCopy) {
      let result = null;
      if (Object.prototype.hasOwnProperty.call(dataset, key)) {
        result = dataset[key];
      }

      if (result && deepCopy) {
        result = cloneDeep(result);
      }

      return result;
    },
    invalidate(key) {
      if (Object.prototype.hasOwnProperty.call(dataset, key)) {
        delete dataset[key];
      }
    },
    clear() {
      dataset = {};
    },
    keys() {
      return Object.getOwnPropertyNames(dataset);
    },
    type: 'memoryStorage',
  };

  return MemoryStorage;
}
