import _ from 'underscore';
import * as $url from '@bingads-webui/url-util';
import { URLMakeOptions, HTTPOptions, StringDictionary } from '../types';

export function host(options: URLMakeOptions) {
  return options.host;
  // NOFIX:
  // return config.has(options.host)? config.get(options.host) : options.host;
}

export function path(options: URLMakeOptions) {
  return options.path;
  // NOFIX:
  // return config.has(options.path)? config.get(options.path) : options.path;
}

export function fill(url: string, options: URLMakeOptions = {}) {
  let ret = url;
  let params: StringDictionary = {};

  // NOFIX:
  // if (options.use_state !== false) {
  //   params = state.attributes;
  // }

  if (options.params) {
    params = _.extend({}, params, options.params);
  }

  // try to replace with value from params; otherwise keep as original
  ret = url.replace(/:([a-zA-Z_]+)/g, (match: string, key: string) => (_.has(params, key) ? params[key] : match));

  return ret;
}

export function expand(url: string, options: URLMakeOptions = {}) {
  let ret = url;
  const expandOptions = _.defaults(options, {
    composeBatchUrl: true,
  });

  if (expandOptions.composeBatchUrl && (expandOptions.host && !new RegExp(`^${host(expandOptions)}`).test(ret))) {
    ret = $url.join(host(expandOptions) || '', path(expandOptions) || '', ret);
  }

  // replace all param placeholders by backbone style slugs
  // ex: /Customers()/Accounts()/ becomes /Customers(:customer_id)/Accounts(:account_id)/
  ret = ret.replace(/\/((\w+?)s?)(\(\))/g, (match: string, col: string, type: string) => `/${col}(:${type.toLowerCase()}_id)`);

  return ret;
}

export function make(url: string, options: URLMakeOptions) {
  return fill(expand(url, options), options);
}

export function queryify(url: string, {
  method,
  data,
  customParamSerialize,
  urlParameters,
}: HTTPOptions<any>) {
  const isGet = !method || method === 'GET';
  if (isGet && _.isObject(data)) {
    // $.param is not supported by node, so use custom param serialization function
    return $url.queryify(url, <JQuery.PlainObject>data, customParamSerialize);
  }

  const isPostOrPatch = !method || method === 'POST' || method === 'PATCH';
  if (isPostOrPatch && _.isObject(urlParameters)) {
    // $.param is not supported by node, so use custom param serialization function
    return $url.queryify(url, <JQuery.PlainObject>urlParameters, customParamSerialize);
  }

  return url;
}

export function getDomain() {
  return document.domain;
}

export function getProtocol() {
  return window.location.protocol;
}

export function getPort() {
  return window.location.port;
}
