import $ from 'jquery';
import Promise from 'bluebird';

const defaultAjaxOptions = {
  xhrFields: {
    withCredentials: true,
  },
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
  },
};

/**
 * Client Center  Customer Service relevant for Customer Related Operations like fetch and manage
 * @param {String} ccBaseUrl - represents base url for client center site
 * @param {Object} ccJsData - JsData object for caching response
 * @param {function} getUserPreferences() - asynchronous Ajax funtion to get a user's preferences
 * @param {function} setUserPreferences() - asynchronous Ajax funtion to set a user's preferences
 * @param {function} getCustomers() - asynchronous Ajax funtion to get Customers under a Person
 * @param {function} getRecentAccesssEntities() - asynchronous Ajax funtion to get recently accessed
 * entities under a customer
 * @param {function} getCustomerHierarchy() - asynchronous Ajax funtion to get linked
 * accounts and customers for a given customer id
 * return Promise for the corresponding API response
 * @param {function} getAccount() - asynchronous Ajax funtion to get Account
 */
export class ClientCenterCustomerService {
  constructor({
    ccBaseUrl,
    ccJsData,
  }) {
    this.baseCustomerUrl = ccBaseUrl;
    this.ccJsData = ccJsData;
  }

  getUserPreferences({ uid, id }) {
    if (this.ccJsData) {
      return this.ccJsData.UserPreference.findAll({ uid, id });
    }

    const url = this.baseCustomerUrl.concat('/Api/Preference/UserPreference');

    return Promise.resolve($.ajax({
      url,
      data: {
        uid,
        id,
      },
      ...defaultAjaxOptions,
    }));
  }

  setUserPreferences({ data, uid, id }) {
    const url = this.baseCustomerUrl.concat('/Api/Preference/UserPreference');

    return new Promise((resolve, reject) => {
      $.ajax({
        url,
        data: {
          data,
          uid,
          id,
        },
        ...defaultAjaxOptions,
        type: 'POST',
        success: response => resolve(response),
        error: (xhr, status) => reject(status),
      });
    });
  }

  // ToDO: Task 1460962: Add Test Case for the ClientCenterCustomerService Shared Component
  getCustomers() {
    const getCustomersListUrl = this.baseCustomerUrl.concat('/Customers/GetCustomers');

    //  $.getJSON throws parse error for Cross Domain Requests. using $.ajax with xhrFields
    //  checks for authorization before making the call and allows cross domain requests
    return Promise.resolve($.ajax({
      url: getCustomersListUrl,
      data: {
        rurl: window.location.href,
      },
      ...defaultAjaxOptions,
    }));
  }

  getRecentAccesssEntities({ cid, uid, limit = null }) {
    const getRecentAccesssEntitiesUrl = this.baseCustomerUrl.concat('/Customers/RecentAccessedEntities');

    return Promise.resolve($.ajax({
      url: getRecentAccesssEntitiesUrl,
      data: {
        cid,
        uid,
        limit,
        rurl: window.location.href,
      },
      ...defaultAjaxOptions,
    }));
  }

  getCustomerHierarchy({
    cid,
    uid,
    searchTerm = null,
    start = null,
    limit = null,
    returnOnlyDirectlyLinkedEntities = true,
  }) {
    const options = {
      cid,
      uid,
      targetCid: cid,
      searchTerm,
      start,
      limit,
      returnOnlyDirectlyLinkedEntities,
      rurl: window.location.href,
    };

    if (this.ccJsData) {
      return this.ccJsData.CustomerHierarchy.find(cid, {
        params: options,
        bypassCache: true,
      });
    }

    const getCustomerHierarchyUrl = this.baseCustomerUrl.concat('/Customers/SearchCustomerHierarchy');

    return Promise.resolve($.ajax({
      url: getCustomerHierarchyUrl,
      data: options,
      ...defaultAjaxOptions,
    }));
  }

  getAncestorHierarchyPath({
    cid,
    uid,
    entityId,
    isCustomer,
  }) {
    const options = {
      cid,
      uid,
      targetCid: cid,
      entityId,
      isCustomer,
    };

    if (this.ccJsData) {
      return this.ccJsData.AncestorHierarchyPath.find(`${entityId}_${cid}`, {
        params: options,
        bypassCache: true,
      });
    }

    const getAncestorHierarchyPathUrl = this.baseCustomerUrl.concat('/Customers/AncestorHierarchyPath');

    return Promise.resolve($.ajax({
      url: getAncestorHierarchyPathUrl,
      data: options,
      ...defaultAjaxOptions,
    }));
  }

  getAccount({ uid, id, cid }) {
    const url = this.baseCustomerUrl.concat('/ApI/Account');

    return Promise.resolve($.ajax({
      url,
      data: {
        uid,
        id,
        cid,
      },
      ...defaultAjaxOptions,
    }));
  }
}
