import _ from 'underscore';

const DefaultTop = 20;

export function convert({
  where = {},
  expand,
  limit,
  offset,
  orderBy,
  query,
  select,
  source,
  any,
} = {}) {
  const ret = {};

  const { '*': { contains: search } = {} } = where;

  if (!_.isEmpty(search)) {
    ret.$search = search;
  }

  if (_.isString(where) && !_.isEmpty(where)) {
    // If 'where' is a string, then directly use it as '$filter' without any further conversion.
    // In this case, 'where' is a well-formed, non-URL-encoded string, such as:
    // "(PerformanceMetrics/Clicks gt 10 and contains(Name, 'Campaign')
    // and HasAuctionInsight eq true)"
    ret.$filter = where;
  }

  if (!_.isEmpty(expand) && _.isArray(expand)) {
    ret.$expand = expand.join(',');
  }

  if (_.isNull(limit) || _.isUndefined(limit)) {
    ret.$top = DefaultTop;
  } else if (!_.isNumber(limit) || _.isNaN(limit)) {
    throw new TypeError('limit must be a number and not NaN');
  } else if (limit !== Number.MAX_VALUE) {
    ret.$top = limit;
  }

  if (!_.isUndefined(offset)) {
    ret.$skip = offset;
  }

  ret.$count = true;

  if (!_.isUndefined(source)) {
    ret.$source = source;
  }
  // todo [akamel] only supports one order column
  // todo [akamel] this expects mongo style orderby obj; rely on js-data syntax for this instead...
  if (!_.isEmpty(orderBy)) {
    if (_.isString(orderBy)) {
      ret.$orderby = `${orderBy} asc`;
    } else if (_.isArray(orderBy)) {
      const arr = orderBy.map((item) => {
        if (_.isString(item)) {
          return `${item} asc`;
        } else if (_.isArray(item)) {
          return `${item[0]} ${item[1].toLowerCase()}`;
        } else if (_.isObject(item)) {
          const name = _.keys(item)[0];
          const direction = item[name] > 0 ? 'asc' : 'desc';

          return `${name} ${direction}`;
        }
        return null;
      }).filter(item => item);

      ret.$orderby = arr.join(',');
    }
  }

  if (!_.isEmpty(select)) {
    ret.$select = select.join(',');
  }

  if (_.isObject(any)) {
    _.each(_.keys(any), (key) => {
      const value = any[key];
      if (_.isString(value)) {
        ret[key] = any[key];
      }
    });
  }

  return _.extend(ret, _.omit(query, 'entitySet'));
}
